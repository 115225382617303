import React, { useEffect, useState } from "react";

function Contract({ contract, img, name }) {
  const [iscopied, setiscopied] = useState(false);
  const [theme, settheme] = useState("light");

  useEffect(() => {
    if (localStorage.getItem("theme")) {
      if (localStorage.getItem("theme") == "is_dark") {
        settheme("dark");
      } else {
        settheme("light");
      }
    }
  }, []);

  const HandleCopy = (e) => {
    setiscopied(true);
    navigator.clipboard.writeText(e);

    setTimeout(() => {
      setiscopied(false);
    }, 3000);
  };

  return (
    <div>
      {contract && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <div>
              <img
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                }}
                src={img}
                alt=""
              />
            </div>
            <div
              style={{
                fontWeight: "500",
                color: theme == "light" ? "black" : "white",
              }}
            >
              {name}
            </div>
          </div>

          <div style={{ display: "flex", gap: "10px" }}>
            <div style={{ marginTop: "5px" }}>
              {contract.slice(0, 6) +
                "..." +
                contract.slice(contract.length - 8, contract.length)}
            </div>
            <div
              style={{ fontSize: "20px", cursor: "pointer", transition: ".4s" }}
              onClick={() => {
                HandleCopy(contract);
              }}
            >
              {iscopied ? (
                <i style={{ color: "blue" }} class="fa-solid fa-check"></i>
              ) : (
                <i
                  style={{ color: theme == "light" ? "black" : "white" }}
                  class="fa-regular fa-copy"
                ></i>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Contract;
