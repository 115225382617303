import React, { useContext, useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import Sale01 from "../components/sale/Sale01";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import PageTitle from "../components/pagetitle";
import PageHead from "../components/pagetitle/PageHead";

import { Link } from "react-router-dom";
import axios from "axios";
import { LoginContext } from "../components/LoginContext";
import Check from "../assets/images/icon/check.png";
import Loader from "../components/Loader";

Register.propTypes = {};

function Register(props) {
  const [Username, setUsername] = useState(null);
  const [Email, setEmail] = useState(null);
  const [Password, setPassword] = useState(null);
  const [ConfirmPass, setConfirmPass] = useState(null);
  const [showPopup, setshowPopup] = useState(false);
  const [loading, setloading] = useState(false);
  const [isDisabled, setisDisabled] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const { isLoggedin, theme } = useContext(LoginContext);
  const [showPass, setshowPass] = useState(false);
  const [showPass2, setshowPass2] = useState(false);

  function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }

  function HandleRegister() {
    if (
      Username?.length > 0 &&
      Email?.length > 0 &&
      Password?.length > 0 &&
      ConfirmPass?.length > 0
    ) {
      if (
        Email.toLowerCase().match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        if (checkPassword(Password)) {
          if (Password == ConfirmPass) {
            setloading(true);
            fetch(
              process.env.REACT_APP_SERVER_URL + "/api/auth/local/register",
              {
                method: "POST",
                headers: {
                  "Content-type": "Application/json",
                },
                body: JSON.stringify({
                  username: Username,
                  email: Email,
                  password: Password,
                }),
              }
            )
              .then((res) => res.json())
              .then((data) => {
                setloading(false);
                if (data.error) {
                  setErrorMessage(data.error.message);
                } else {
                  setshowPopup(true);
                  setisDisabled(true);
                }
              });
          } else {
            setErrorMessage("Confirm Password doesn't match");
          }
        } else {
          setErrorMessage(
            "Password at least has 8 characters, one lower and upper case letters, one number and one special character"
          );
        }
      } else {
        setErrorMessage("Email format is invalid");
      }
    } else {
      setErrorMessage("Please fill all fields");
    }

    // axios
    //   .post(process.env.REACT_APP_SERVER_URL + "/api/auth/local/register", {
    //     username: Username,
    //     email: Email,
    //     password: Password,
    //   })
    //   .then((res) => {
    //     if (res.status == 200) {
    //       setshowPopup(true);
    //       setisDisabled(true);
    //       // window.location.href = "/";
    //     }
    //   });
  }
  useLayoutEffect(() => {
    document.title = "Register | PlaystoEarn";
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", "Register");
  }, []);
  if (!isLoggedin) {
    return (
      <div>
        {loading && <Loader />}

        <PageTitle heading="Register" title="Register" />

        {showPopup && (
          <div className="popup_cont">
            <div
              className="popup"
              style={{ background: theme == "light" ? "white" : "black" }}
            >
              <div>
                <img
                  style={{ width: "200px", height: "150px" }}
                  src={Check}
                  alt=""
                />
              </div>

              <div>
                <div>Account registered successfully</div>
                <div>Please verfiy your email and login</div>
              </div>

              <div style={{ width: "100%" }}>
                <div
                  onClick={() => {
                    window.location.href = "/login";
                  }}
                  style={{ width: "100%", textAlign: "center" }}
                  className="btn-action"
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        )}

        {showPopup && (
          <div className="popup_cont">
            <div
              className="popup"
              style={{ background: theme == "light" ? "white" : "black" }}
            >
              <div>
                <img
                  style={{ width: "200px", height: "150px" }}
                  src={Check}
                  alt=""
                />
              </div>

              <div>
                <div>Account registered successfully</div>
                <div>Please verfiy your email and login</div>
              </div>

              <div style={{ width: "100%" }}>
                <div
                  onClick={() => {
                    window.location.href = "/login";
                  }}
                  style={{ width: "100%", textAlign: "center" }}
                  className="btn-action"
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        )}

        <section className="register">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="block-text center">
                  <h3 className="heading">Register</h3>
                  <p className="desc fs-20">
                    Register in advance and enjoy the benefits
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <Tabs>
                  <TabPanel>
                    <div className="content-inner">
                      <form>
                        <div className="form-group">
                          <label>Username</label>
                          <input
                            style={{
                              border:
                                Username == null || Username?.length > 0
                                  ? "1px solid #ced4da"
                                  : "1px solid red",
                            }}
                            onChange={(e) => {
                              setUsername(e.target.value);
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Enter Username"
                          />
                        </div>
                        <div className="form-group">
                          <label for="exampleInputEmail1">Email</label>
                          <input
                            style={{
                              border:
                                Email == null || Email?.length > 0
                                  ? "1px solid #ced4da"
                                  : "1px solid red",
                            }}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Please fill in the email form."
                          />
                        </div>

                        <div className="form-group">
                          <label>
                            Password
                            <span></span>
                          </label>

                          <div
                            className="flex "
                            style={{
                              background: "white",
                              alignItems: "center",
                              borderRadius: "8px",
                              paddingRight: "10px",
                              border:
                                Password == null || Password?.length > 0
                                  ? "1px solid #ced4da"
                                  : "1px solid red",
                            }}
                          >
                            <input
                              style={{
                                border: "none",
                                margin: "0px",
                              }}
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                              type={showPass ? "text" : "password"}
                              className="form-control mb-10"
                              placeholder="Please enter a password."
                            />
                            <div>
                              <i
                                onClick={() => {
                                  setshowPass(!showPass);
                                }}
                                class={
                                  showPass
                                    ? "fa-solid fa-eye-slash"
                                    : "fa-solid fa-eye"
                                }
                                style={{ color: "black", cursor: "pointer" }}
                              ></i>
                            </div>
                          </div>
                          <br />

                          <div
                            className="flex "
                            style={{
                              background: "white",
                              alignItems: "center",
                              borderRadius: "8px",
                              paddingRight: "10px",
                              border:
                                Password == null || Password?.length > 0
                                  ? "1px solid #ced4da"
                                  : "1px solid red",
                            }}
                          >
                            <input
                              style={{
                                border: "none",
                              }}
                              onChange={(e) => {
                                setConfirmPass(e.target.value);
                              }}
                              type={showPass2 ? "text" : "password"}
                              className="form-control"
                              placeholder="Please re-enter your password."
                            />
                            <div>
                              <i
                                onClick={() => {
                                  setshowPass2(!showPass2);
                                }}
                                class={
                                  showPass2
                                    ? "fa-solid fa-eye-slash"
                                    : "fa-solid fa-eye"
                                }
                                style={{ color: "black", cursor: "pointer" }}
                              ></i>
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            fontWeight: "bold",
                            color: "red",
                            fontSize: "17px",
                            marginTop: "10px",
                            marginBottom: "20px",
                          }}
                        >
                          {ErrorMessage}
                        </div>

                        <div
                          onClick={!isDisabled ? HandleRegister : ""}
                          style={{ width: "100%", textAlign: "center" }}
                          type="submit"
                          className="btn-action"
                        >
                          Register
                        </div>
                        <div className="bottom">
                          <p>Already have an account?</p>
                          <Link to="/login">Login</Link>
                        </div>
                      </form>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="content-inner">
                      <form>
                        <div className="form-group">
                          <label for="exampleInputEmail1">Mobile Phone</label>
                          <div>
                            <select className="form-control">
                              <option>+1</option>
                              <option>+84</option>
                              <option>+82</option>
                              <option>+32</option>
                            </select>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Your Phone number"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>
                            Password
                            <span>
                              (8 or more characters, including numbers and
                              special characters)
                            </span>
                          </label>
                          <input
                            type="password"
                            className="form-control mb-10"
                            placeholder="Please enter a password."
                          />
                          <input
                            type="password"
                            className="form-control"
                            id="exampleInputPassword2"
                            placeholder="Please re-enter your password."
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            NickName
                            <span className="fs-14">
                              (Excluding special characters)
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Email"
                          />
                        </div>
                        <div className="form-group">
                          <label>Country </label>
                          <select
                            className="form-control"
                            id="exampleFormControlSelect1"
                          >
                            <option>South Korea (+82)</option>
                            <option>Vietnamese (+84)</option>
                            <option>South Korea (+82)</option>
                            <option>South Korea (+82)</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label>
                            Phone
                            <span className="fs-14">(Enter numbers only)</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="ex) 01012345678 (without '-')"
                          />
                        </div>

                        <div className="form-group">
                          <label>UID Code </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Please enter your invitation code."
                          />
                        </div>

                        <button type="submit" className="btn-action">
                          Pre-Registration
                        </button>
                        <div className="bottom">
                          <p>Already have an account?</p>
                          <Link to="/login">Login</Link>
                        </div>
                      </form>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else if (isLoggedin == true) {
    window.location.href = "/";
    return <div></div>;
  }
}

export default Register;
