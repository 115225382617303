import React, { useContext, useState } from "react";
import "./SelectFilter.css";
import Select from "react-select";
import { LoginContext } from "../LoginContext";

export default function SelectFilter({
  label,
  onchange,
  value,
  options,
  placeholder = "Select Category... ",
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const { theme } = useContext(LoginContext);
  return (
    <div className={"input_body"}>
      <Select
        classNamePrefix="react-select"
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            padding: "5px",
            borderRadius: "100px",
            border: "1px solid rgb(0 0 0 / 8 %)",
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);

            return {
              ...styles,
              zIndex: "999999",

              color:
                theme == "light"
                  ? isSelected
                    ? "#3772ff"
                    : "black"
                  : isSelected
                  ? "rgb(55, 114, 255)"
                  : "white",
              background: theme == "light" ? "white" : "#141416",
            };
          },
        }}
        // theme={(theme) => ({
        //   ...theme,
        //   borderRadius: 0,
        //   colors: {
        //     ...theme.colors,
        //     primary25: "#3772ff",
        //     primary: "#3772ff",
        //   },
        // })}
        value={value}
        defaultValue={value}
        onChange={onchange}
        options={options}
        placeholder={placeholder}
      />
    </div>
  );
}
