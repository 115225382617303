import React, { useEffect, useLayoutEffect } from "react";
import Button from "../components/button";
import NotFoundSVG from "../assets/images/icon/404.svg";
import { Link, useNavigate } from "react-router-dom";
import PageHead from "../components/pagetitle/PageHead";

function Page404(props) {
  const naviage = useNavigate();

  useLayoutEffect(() => {
    document.title = "404 | PlaystoEarn";
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", "404");
  }, []);
  return (
    <div style={{ textAlign: "center", padding: "30px", padding: "120px 0px" }}>
      <img
        src={NotFoundSVG}
        alt="404 Not Found"
        style={{ maxWidth: "100%", width: "300px", height: "auto" }}
      />

      <h3 style={{ marginTop: "20px" }}>Oops! Page not found</h3>
      <p>
        The page you are looking for might have been removed, had its name
        changed, or is temporarily unavailable.
      </p>
      <br />
      <Link to="/">Go back to the homepage</Link>
    </div>
  );
}

export default Page404;
