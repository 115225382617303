import React, { useContext, useEffect, useState } from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import dataBlog from "../assets/fake-data/data-blog";

import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

import { Link } from "react-router-dom";
import Sale01 from "../components/sale/Sale01";
import PageTitle from "../components/pagetitle";
import axios from "axios";

import { Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import PageHead from "../components/pagetitle/PageHead";
import InfiniteScroll from "react-infinite-scroll-component";
import SelectField from "../components/Select/Select";
import { LoginContext } from "../components/LoginContext";

function BlogGrid01(props) {
  const [isOpen, setOpen] = useState(false);

  const [dataCrytoTab] = useState([
    {
      id: 1,
      title: "View All",
    },
    {
      id: 2,
      title: "Learn & Earn",
    },
    {
      id: 3,
      title: "Metaverse",
    },
    {
      id: 4,
      title: "Energy",
    },
    {
      id: 5,
      title: "NFT",
    },
    {
      id: 6,
      title: "Gaming",
    },
    {
      id: 7,
      title: "Music",
    },
  ]);

  const [Data, setData] = useState([]);
  const [FilteredData, setFilteredData] = useState([]);
  const [SelectedCategory, setSelectedCategory] = useState("*");
  const [Categories, setCategories] = useState([]);
  const [SearchText, setSearchText] = useState("");
  const [page, setpage] = useState("1");
  const [hasMore, sethasMore] = useState(true);
  const { theme } = useContext(LoginContext);
  useEffect(() => {
    if (window.location.href[window.location.href.length - 1] == "/")
      window.location.href = window.location.href.slice(0, -1);
    axios(
      process.env.REACT_APP_SERVER_URL +
        `/api/blogs?pagination[page]=${page}&pagination[pageSize]=6&sort=id:desc&populate=*`
    ).then((res) => {
      if (res.status == 200) {
        setData(res.data.data);
        setFilteredData(res.data.data);
      }
    });
    axios(process.env.REACT_APP_SERVER_URL + "/api/categories").then((res) => {
      if (res.status == 200) {
        let cates = [];
        cates.push({
          value: "*",
          label: "Show All",
        });
        res.data.data.forEach((ele) => {
          cates.push({
            value: ele.attributes.title,
            label: ele.attributes.title,
          });
        });

        setCategories(cates);
      }
    });
  }, []);

  const LoadMore = () => {
    axios(
      process.env.REACT_APP_SERVER_URL +
        `/api/blogs?pagination[page]=${(
          parseInt(page) + 1
        ).toString()}&pagination[pageSize]=6&sort=id:desc&populate=*`
    ).then((res) => {
      if (res.status == 200) {
        setpage((parseInt(page) + 1).toString());
        setData((prevItems) => [...prevItems, ...res.data.data]);

        console.log(SelectedCategory);
        // HandleCategoryChange(SelectedCategory);
        let filteredData = [];
        if (SelectedCategory != "*") {
          filteredData = res.data.data.filter((ele) => {
            return ele.attributes.categories.data.some(
              (cate) =>
                cate.attributes.title.toLowerCase() ==
                SelectedCategory.toLowerCase()
            );
          });
        } else {
          filteredData = res.data.data;
        }
        setFilteredData((prevItems) => [...prevItems, ...filteredData]);
      }
    });
  };

  const HandleCategoryChange = (category) => {
    let filteredData = [];
    setSelectedCategory(category);
    if (category != "*") {
      filteredData = Data.filter((ele) => {
        return ele.attributes.categories.data.some(
          (cate) =>
            cate.attributes.title.toLowerCase() == category.toLowerCase()
        );
      });
    } else {
      filteredData = Data;
    }

    if (SearchText.length > 0) {
      let searchedData = filteredData.filter((ele) =>
        ele.attributes.title.toLowerCase().includes(SearchText.toLowerCase())
      );

      setFilteredData(searchedData);
    } else {
      setFilteredData(filteredData);
    }
  };

  const HandleCategoryChangeWithSearch = (category, search = "") => {
    let filteredData = [];
    setSelectedCategory(category);
    if (category != "*") {
      filteredData = Data.filter((ele) => {
        return ele.attributes.categories.data.some(
          (cate) =>
            cate.attributes.title.toLowerCase() == category.toLowerCase()
        );
      });
    } else {
      filteredData = Data;
    }

    if (search.length > 0) {
      let searchedData = filteredData.filter((ele) =>
        ele.attributes.title.toLowerCase().includes(search.toLowerCase())
      );

      setFilteredData(searchedData);
    } else {
      setFilteredData(filteredData);
    }
  };

  const HandleSearch = (e) => {
    let tempval = e.target.value;
    setSearchText(tempval);

    HandleCategoryChangeWithSearch(SelectedCategory, e.target.value);
  };

  return (
    <div style={{ flex: "1" }}>
      <PageTitle heading="Blog" title="Blog" />

      <section className="blog-grid">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Tabs>
                <div className="filter_search_blog">
                  <SelectField
                    options={Categories}
                    label={""}
                    onchange={(e) => {
                      HandleCategoryChange(e.value);
                    }}
                  />
                  <div className="search" style={{ position: "unset " }}>
                    <div action="#">
                      <div
                        className="form-group"
                        style={{ position: "relative" }}
                      >
                        <input
                          value={SearchText}
                          onChange={(e) => {
                            HandleSearch(e);
                          }}
                          type="text"
                          className="form-control"
                          placeholder="Search Post"
                          style={{
                            color: theme == "light" ? "black" : "white",
                          }}
                        />
                        <button type="submit" className="search">
                          <i className="fa-solid fa-magnifying-glass"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <InfiniteScroll
                  dataLength={FilteredData?.length}
                  next={LoadMore}
                  hasMore={hasMore}
                  loader={<div></div>}
                  style={{ overflowX: "hidden" }}
                >
                  <div
                    className="content-inner row"
                    style={{ marginTop: "5vh" }}
                  >
                    {FilteredData != null
                      ? FilteredData.map((ele) => {
                          let date = new Date(ele.attributes.createdAt);
                          let month = date.toLocaleString("default", {
                            month: "short",
                          });
                          let day = date.getDate();
                          let year = date.getFullYear();

                          let full_date = `${month} ${day}, ${year}`;

                          return (
                            <Link
                              to={"/blog/" + ele.attributes.slug}
                              key={ele.id}
                              className="col-md-4"
                            >
                              <div className="blog-box">
                                <div className="box-image">
                                  <img
                                    style={{
                                      height: "300px",
                                      objectFit: "cover",
                                    }}
                                    src={
                                      process.env.REACT_APP_SERVER_URL +
                                      ele.attributes.thumbnail.data.attributes
                                        .url
                                    }
                                    alt="Rockie"
                                  />
                                </div>

                                <div
                                  className="box-content"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Link
                                      to={
                                        ele?.attributes?.categories?.data[0]
                                          ?.attributes?.title
                                          ? "/category/" +
                                            ele?.attributes?.categories?.data[0]?.attributes?.title
                                              .toLowerCase()
                                              .replaceAll(" ", "-")
                                          : "#"
                                      }
                                      className="category btn-action"
                                      style={{ width: "fit-content" }}
                                    >
                                      {ele?.attributes?.categories?.data[0]
                                        ?.attributes?.title
                                        ? ele?.attributes?.categories?.data[0]
                                            ?.attributes?.title
                                        : "NO TAG"}
                                    </Link>
                                    <div
                                      to="#"
                                      className="time"
                                      style={{ color: "#777e90" }}
                                    >
                                      {full_date}
                                    </div>
                                  </div>

                                  <div
                                    to="#"
                                    className="title"
                                    style={{ marginTop: "20px" }}
                                  >
                                    {ele.attributes.title}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          );
                        })
                      : Data.map((ele) => {
                          let date = new Date(ele.attributes.createdAt);
                          let month = date.toLocaleString("default", {
                            month: "short",
                          });
                          let day = date.getDate();
                          let year = date.getFullYear();

                          let full_date = `${month} ${day}, ${year}`;

                          return (
                            <Link
                              to={"/blog/" + ele.attributes.slug}
                              key={ele.id}
                              className="col-md-4"
                            >
                              <div className="blog-box">
                                <div className="box-image">
                                  <img
                                    style={{
                                      height: "300px",
                                      objectFit: "cover",
                                    }}
                                    src={
                                      process.env.REACT_APP_SERVER_URL +
                                      ele.attributes.thumbnail.data.attributes
                                        .url
                                    }
                                    alt="Rockie"
                                  />
                                </div>

                                <div
                                  className="box-content"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Link
                                      to="#"
                                      className="category btn-action"
                                      style={{ width: "fit-content" }}
                                    >
                                      {ele?.attributes?.categories?.data[0]
                                        ?.attributes.title
                                        ? ele?.attributes?.categories?.data[0]
                                            ?.attributes.title
                                        : "NO TAG"}
                                    </Link>
                                    <Link
                                      to="#"
                                      className="time"
                                      style={{ color: "#777e90" }}
                                    >
                                      {full_date}
                                    </Link>
                                  </div>

                                  <Link
                                    to="#"
                                    className="title"
                                    style={{ marginTop: "20px" }}
                                  >
                                    {ele.attributes.title}
                                  </Link>
                                </div>
                              </div>
                            </Link>
                          );
                        })}

                    <div className="col-md-12"></div>
                  </div>
                </InfiniteScroll>
              </Tabs>
            </div>
          </div>
        </div>

        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="i7EMACWuErA"
          onClose={() => setOpen(false)}
        />
      </section>
    </div>
  );
}

export default BlogGrid01;
