const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
  },
  {
    id: 2,
    name: "Markets",
    links: "/game-markets",
  },
  {
    id: 3,
    name: "Earnings",
    links: "/game-earnings",
  },
  {
    id: 4,
    name: "Whitepaper",
    links: "https://docs.playstoken.com/",
  },
  {
    id: 5,
    name: "Blog",
    links: "/blog",
  },
];

export default menus;
