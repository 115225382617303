import React, { useState, useEffect, useContext } from "react";

import { Link, NavLink } from "react-router-dom";
import menus from "../../pages/menu";
import { Dropdown } from "react-bootstrap";

import "./styles.scss";
import logo from "../../assets/images/logo/logo.png";
import logodark from "../../assets/images/logo/logo-dark.png";
import avt from "../../assets/images/avt/avt-01.jpg";
import DarkMode from "./DarkMode";

import icon1 from "../../assets/images/flags/us.jpg";
import icon2 from "../../assets/images/flags/spain.jpg";
import icon3 from "../../assets/images/flags/germany.jpg";
import icon4 from "../../assets/images/flags/italy.jpg";
import icon5 from "../../assets/images/flags/russia.jpg";
import StatsHeader from "./StatsHeader";
import { LoginContext } from "../LoginContext";
import Profile from "../../assets/images/icon/profile.png";
// import Button from '../button';

const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [theme, settheme] = useState("light");
  useEffect(() => {
    window.addEventListener("scroll", () => {
      // setScroll(window.scrollY > 300);
    });
    if (localStorage.getItem("theme")) {
      if (localStorage.getItem("theme") == "is_dark") {
        settheme("dark");
      } else {
        settheme("light");
      }
    }
    return () => {
      setScroll({});
    };
  }, []);

  const [menuActive, setMenuActive] = useState(null);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleDropdown = (index) => {
    setActiveIndex(index);
  };

  const { isLoggedin, User } = useContext(LoginContext);

  function HandleLogout() {
    localStorage.removeItem("jwt");
    window.location.reload();
  }

  return (
    <header id="header_main" className={`header ${scroll ? "is-fixed" : ""}`}>
      <div className="container">
        <StatsHeader />
        <div className="row">
          <div className="col-12 mobile_sm_padding">
            <div className="header__body d-flex justify-content-between">
              <div className="header__left">
                <div className="logo">
                  <NavLink to="/" className="light">
                    <img src={logo} alt="Rockie" className="header_logo" />
                  </NavLink>
                  <NavLink to="/" className="dark">
                    <img src={logodark} alt="Rockie" className="header_logo" />
                  </NavLink>
                </div>
                <div className="left__main">
                  <nav
                    id="main-nav"
                    className={
                      theme == "light"
                        ? `main-nav ${menuActive ? "active" : ""}`
                        : `main-nav menu_dark ${menuActive ? "active" : ""}`
                    }
                  >
                    <ul id="menu-primary-menu" className="menu">
                      {menus.map((data, idx) => (
                        <li
                          key={idx}
                          onClick={() => handleDropdown(idx)}
                          className={`menu-item ${
                            data.namesub ? "menu-item-has-children" : ""
                          } ${activeIndex === idx ? "active" : ""}`}
                        >
                          <a href={data.links}>{data.name}</a>
                          {data.namesub && (
                            <ul
                              className={
                                theme == "light"
                                  ? "sub-menu"
                                  : "menu_dark sub-menu"
                              }
                            >
                              {data.namesub.map((submenu) => (
                                <li key={submenu.id} className="menu-item">
                                  <NavLink to={submenu.links}>
                                    {submenu.sub}
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>

              <div className="header__right">
                <DarkMode />

                <div
                  className={`mobile-button ${menuActive ? "active" : ""}`}
                  onClick={handleMenuActive}
                >
                  <i class="fa-solid fa-bars"></i>
                </div>

                {isLoggedin ? (
                  <>
                    <Dropdown className="user">
                      <Dropdown.Toggle>
                        <img
                          style={{
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                          src={
                            User?.img
                              ? process.env.REACT_APP_PROXY_URL + User?.img
                              : Profile
                          }
                          alt="User profile"
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        className={theme == "dark" ? "search_dark " : ""}
                      >
                        <Dropdown.Item href="#">
                          <Link
                            className={
                              theme == "dark"
                                ? "dropdown-item search_icon_dark sp_hover"
                                : "dropdown-item"
                            }
                            to="/user-profile"
                          >
                            <i className="bx bx-user font-size-16 align-middle me-1"></i>
                            <span>Profile</span>
                          </Link>
                        </Dropdown.Item>

                        <Dropdown.Item href="#">
                          <div
                            className="dropdown-item text-danger sp_hover"
                            onClick={HandleLogout}
                          >
                            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                            <span>Logout</span>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                ) : (
                  <>
                    <div className="wallet">
                      <Link to="/login"> Login </Link>
                    </div>
                    <div className="wallet" style={{ display: "flex" }}>
                      <Link
                        className="btn-action"
                        style={{ borderColor: "#3772ff" }}
                        to="/register"
                      >
                        {" "}
                        Signup{" "}
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
