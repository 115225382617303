import { React, useEffect, useState } from "react";
import AOS from "aos";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import "../src/assets/font/font-awesome.css";
import routes from "./pages";
import Page404 from "./pages/404";
import StatsHeader from "./components/header/StatsHeader";
import { LoginContext } from "./components/LoginContext";
import axios from "axios";

function App() {
  const [isLoggedin, setisLoggedin] = useState(null);
  const [theme, settheme] = useState("light");

  const [User, setUser] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("jwt")) {
      axios(process.env.REACT_APP_SERVER_URL + "/api/users/me?populate=*", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
      })
        .then((res) => {
          if (res.status == 200) {
            setisLoggedin(true);
            setUser({
              username: res.data.username,
              email: res.data.email,
              img: res.data.img,
              id: res.data.id,
            });
          } else {
            setisLoggedin(false);
          }
        })
        .catch((err) => {
          setisLoggedin(false);
        });
    } else {
      setisLoggedin(false);
    }
    if (localStorage.getItem("theme")) {
      if (localStorage.getItem("theme") == "is_dark") {
        settheme("dark");
      } else {
        settheme("light");
      }
    }
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <LoginContext.Provider value={{ isLoggedin, User, theme }}>
      {/* <StatsHeader /> */}
      <Header />

      <Routes>
        {routes.map((data, idx) => (
          <Route key={idx} path={data.path} element={data.component} exact />
        ))}

        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>

      <Footer />
    </LoginContext.Provider>
  );
}

export default App;
