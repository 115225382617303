import React, { useLayoutEffect } from "react";
import PageTitle from "../components/pagetitle";
import PageHead from "../components/pagetitle/PageHead";

function Terms() {
  useLayoutEffect(() => {
    document.title = "Terms of Use | PlaystoEarn";
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", "Terms of Use");
  }, []);
  return (
    <div>
      <PageTitle heading="Terms of Use" title="Terms of Use" />

      <section className="section__space">
        <div className="container terms_cont">
          <div className="terms-area">
            <div className="terms__single">
              <p>
                Welcome to PlaystoEarn! These terms of use govern your use of
                the PlaystoEarn website, platform, and services. By accessing or
                using our website and services, you agree to comply with these
                terms of use in full. If you do not agree with any part of these
                terms of use, please do not use our website or services
              </p>
              <ul>
                <li>
                  &quot;PlaystoEarn&quot; refers to the Web3 Gaming portal
                  project and its associated website and services.
                </li>
                <li>
                  &quot;User&quot; refers to any person who accesses or uses the
                  PlaystoEarn website or services.
                </li>
                <li>
                  &quot;Content&quot; refers to any text, images, audio, video,
                  or other materials uploaded, downloaded, or otherwise made
                  available on the PlaystoEarn platform.
                </li>
              </ul>
              <hr />

              <h4>User Eligibility</h4>
              <p>
                By using PlaystoEarn, you represent and warrant that you are at
                least 18 years old and have the legal capacity to enter into
                these terms of use. If you are accessing or using PlaystoEarn on
                behalf of a company or other legal entity, you represent and
                warrant that you have the authority to bind that entity to these
                terms of use.
              </p>

              <h4>Account Registration</h4>
              <p>
                In order to access certain features of PlaystoEarn, you may be
                required to use a Web3 Wallet. When registering for an account,
                you agree to provide accurate and complete information. You are
                solely responsible for maintaining the confidentiality of your
                account credentials and for all activities that occur under your
                account. You agree to notify PlaystoEarn immediately of any
                unauthorized use of your account or any other breach of
                security.
              </p>

              <h4>Acceptable Use</h4>
              <p>By using PlaystoEarn, you agree not to:</p>
              <ul>
                <li>
                  Use the platform for any unlawful purpose or in violation of
                  any applicable laws or regulations.
                </li>
                <li>
                  Engage in any activity that interferes with or disrupts the
                  operation of PlaystoEarn or the servers and networks connected
                  to PlaystoEarn.
                </li>
                <li>
                  Attempt to gain unauthorized access to any portion of
                  PlaystoEarn website, other user accounts, or any computer
                  systems or networks connected to PlaystoEarn.
                </li>
                <li>
                  Upload, transmit, or distribute any viruses, malware, or other
                  harmful code.
                </li>
                <li>
                  Use any automated means, including robots, spiders, or
                  scrapers, to access PlaystoEarn or collect information from
                  PlaystoEarn.
                </li>
              </ul>

              <h4>Intellectual Property</h4>
              <p>
                All content and materials available on PlaystoEarn, including
                but not limited to text, graphics, logos, images, audio clips,
                video clips, software, and data compilations, are the property
                of PlaystoEarn or its licensors and are protected by copyright,
                trademark, and other intellectual property laws.
              </p>

              <h4>Disclaimer of Warranties</h4>
              <p>
                Your use of PlaystoEarn is at your sole risk. The platform is
                provided on an &quot;as is&quot; and &quot;as available&quot;
                basis. PlaystoEarn makes no representations or warranties of any
                kind, express or implied, regarding the accuracy, reliability,
                or completeness of any content or materials available on the
                platform.
              </p>

              <h4>Limitation of Liability</h4>
              <p>
                In no event shall PlaystoEarn, its affiliates, or their
                respective directors, officers, employees, or agents be liable
                to you or any third party for any indirect, consequential,
                incidental, special, or punitive damages, including without
                limitation damages for lost profits, lost data, or other
                intangible losses, arising out of or relating to your access to
                or use of PlaystoEarn.
              </p>

              <h4>Indemnification</h4>
              <p>
                You agree to indemnify and hold harmless PlaystoEarn, its
                affiliates, and their respective directors, officers, employees,
                and agents, from and against any and all claims, liabilities,
                damages, losses, costs, and expenses (including reasonable
                attorneys&apos; fees) arising out of or relating to your use of
                PlaystoEarn or any violation of these terms of use.
              </p>

              <h4>Changes to Terms of Use</h4>
              <p>
                PlaystoEarn reserves the right to update or modify these terms
                and conditions at any time without prior notice. Changes to
                these terms of use will be effective immediately upon posting.
                By continuing to access or use PlaystoEarn after changes are
                posted, you agree to be bound by the revised terms and
                conditions.
              </p>

              <h4>Governing Law and Jurisdiction</h4>
              <p>
                These terms of use shall be governed by and construed in
                accordance with the laws of the Republic of Cyprus, without
                regard to its conflict of laws principles. Any dispute arising
                out of or relating to these terms of use shall be subject to the
                exclusive jurisdiction of the courts in the Republic of Cyprus.
              </p>

              <h4>Contact Information</h4>
              <p>
                If you have any questions or concerns about these terms and
                conditions, please contact us at support@playstoearn.com.
              </p>

              <p>Last updated: Friday, April 10, 2024</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Terms;
