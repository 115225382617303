import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import Sale01 from "../components/sale/Sale01";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import PageTitle from "../components/pagetitle";
import PageHead from "../components/pagetitle/PageHead";

import { Link, useNavigate, useParams } from "react-router-dom";
import img from "../assets/images/icon/qrcode.png";
import axios from "axios";
import { LoginContext } from "../components/LoginContext";
import Check from "../assets/images/icon/check.png";

function ResetPassword(props) {
  const [Password, setPassword] = useState(null);
  const [NewPassword, setNewPassword] = useState(null);
  const [ConfPassword, setConfPassword] = useState(null);
  const [showPopup, setshowPopup] = useState(false);
  const [isDisabled, setisDisabled] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const { isLoggedin } = useContext(LoginContext);

  const navigate = useNavigate();
  const { id } = useParams();

  function updatePass() {
    if (NewPassword.length > 0 && ConfPassword.length > 0) {
      if (NewPassword.length >= 6) {
        if (NewPassword == ConfPassword) {
          axios
            .post(
              process.env.REACT_APP_SERVER_URL + "/api/auth/reset-password",
              {
                code: id, // code contained in the reset link of step 3.
                password: NewPassword,
                passwordConfirmation: ConfPassword,
              }
            )
            .then((response) => {
              setshowPopup(true);
            })
            .catch((error) => {
              setErrorMessage("An error occurred");
            });
        } else {
          setErrorMessage("Confirm Password does't match the new password");
        }
      } else {
        setErrorMessage("Password has to be at least 6 characters");
      }
    } else {
      setErrorMessage("Please fill all fields");
    }

    // axios
    //   .post(
    //     process.env.REACT_APP_SERVER_URL + "/api/auth/change-password",
    //     {
    //       currentPassword: CurrentPass,
    //       password: NewPass,
    //       passwordConfirmation: ConfirmPass,
    //     },
    //     {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("jwt"),
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     if (res.status == 200) {
    //       console.log(res.data);
    //     }
    //   });
  }

  useLayoutEffect(() => {
    document.title = "Password Reset | PlaystoEarn";
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", "Password Reset");
  }, []);

  if (!isLoggedin) {
    return (
      <div>
        <PageTitle heading="Password Reset" title="Password Reset" />

        {showPopup && (
          <div className="popup_cont">
            <div className="popup">
              <div>
                <img
                  style={{ width: "100px", height: "100px" }}
                  src={Check}
                  alt=""
                />
              </div>

              <div>
                <div>Your password has been changed </div>
              </div>

              <div style={{ width: "100%" }}>
                <Link
                  to={"/login"}
                  style={{ width: "100%", textAlign: "center" }}
                  className="btn-action"
                >
                  Login
                </Link>
              </div>
            </div>
          </div>
        )}

        <section className="register login">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="block-text center">
                  <h3 className="heading">Reset your password</h3>
                </div>
              </div>
              <div className="col-md-12">
                <Tabs>
                  <TabPanel>
                    <div className="content-inner">
                      <form>
                        <div className="form-group s1">
                          <label>New Password </label>
                          <input
                            style={{
                              border:
                                NewPassword == null || NewPassword?.length > 0
                                  ? "1px solid #ced4da"
                                  : "1px solid red",
                            }}
                            onChange={(e) => {
                              setNewPassword(e.target.value);
                            }}
                            type="password"
                            className="form-control"
                            placeholder="New Password"
                          />
                        </div>
                        <div className="form-group s1">
                          <label>Confirm Password </label>
                          <input
                            style={{
                              border:
                                ConfPassword == null || ConfPassword?.length > 0
                                  ? "1px solid #ced4da"
                                  : "1px solid red",
                            }}
                            onChange={(e) => {
                              setConfPassword(e.target.value);
                            }}
                            type="password"
                            className="form-control"
                            placeholder="Confirm Password"
                          />
                        </div>

                        <div
                          style={{
                            fontWeight: "bold",
                            color: "red",
                            fontSize: "17px",
                            marginTop: "10px",
                            marginBottom: "20px",
                          }}
                        >
                          {ErrorMessage}
                        </div>

                        <div
                          onClick={updatePass}
                          type="submit"
                          className="btn-action"
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Reset
                        </div>
                      </form>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else if (isLoggedin == true) {
    window.location.href = "/";
    return <div></div>;
  }
}

export default ResetPassword;
