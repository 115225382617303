import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import Sale01 from "../components/sale/Sale01";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import PageTitle from "../components/pagetitle";
import { Link } from "react-router-dom";
import img from "../assets/images/avt/avt.png";
import { LoginContext } from "../components/LoginContext";
import axios from "axios";
import Profile from "../assets/images/icon/profile.png";
import Mail from "../assets/images/icon/mail.png";
import Check from "../assets/images/icon/check.png";

UserProfile.propTypes = {};

function UserProfile(props) {
  const { isLoggedin, User, theme } = useContext(LoginContext);

  const [dataCoinTab] = useState([
    {
      id: 1,
      title: "User Profile",
      icon: "fa-user",
    },

    {
      id: 2,
      title: "Change password",
      icon: "fa-lock",
    },
  ]);

  const [username, setusername] = useState("");
  const [OrginalUsername, setOrginalUsername] = useState("");
  const [Email, setEmail] = useState("");
  const [OrginalEmail, setOrginalEmail] = useState("");
  const [img, setimg] = useState("");
  const [Files, setFiles] = useState([]);
  const [showPopup, setshowPopup] = useState(false);
  const [showsuccpopup, setshowsuccpopup] = useState(false);
  const [CurrentPass, setCurrentPass] = useState("");
  const [NewPass, setNewPass] = useState("");
  const [ConfirmPass, setConfirmPass] = useState("");
  const [Code, setCode] = useState("");
  const [ErrorMesage, setErrorMesage] = useState("");
  const [PassErrorMessage, setPassErrorMessage] = useState("");
  const [PopupErrorMesage, setPopupErrorMesage] = useState("");
  function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }

  useEffect(() => {
    console.log(User);
    setusername(User?.username);
    setOrginalUsername(User?.username);
    setEmail(User?.email);
    setOrginalEmail(User?.email);

    setimg(User?.img);
  }, [User]);

  function UpdateProfile(id) {
    if (Email.length > 0 && username.length > 0) {
      if (
        Email.toLowerCase().match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        if (Files.length > 0) {
          const formData = new FormData();

          formData.append("image", Files[0]);
          formData.append("jwt", localStorage.getItem("jwt"));

          fetch(process.env.REACT_APP_PROXY_URL + "/upload", {
            method: "POST",
            body: formData,
          })
            .then((res) => res.json())
            .then((data) => {
              console.log(data);
              setimg("/" + data.path);
              setFiles(null);

              axios
                .put(
                  process.env.REACT_APP_SERVER_URL + "/api/user/me",
                  {
                    username: username,

                    img: "/" + data.path,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("jwt"),
                    },
                  }
                )
                .then((res) => {
                  if (res.status == 200) {
                    if (Email != OrginalEmail) {
                      axios(process.env.REACT_APP_PROXY_URL + "/checkEmail", {
                        headers: {
                          email: Email,
                        },
                      }).then((res) => {
                        if (res.status == 200) {
                          if (res.data.length > 0) {
                            setErrorMesage("Email not available");
                          } else {
                            axios
                              .post(
                                process.env.REACT_APP_PROXY_URL + "/sendEmail",
                                {
                                  jwt: localStorage.getItem("jwt"),
                                  email: Email,
                                }
                              )
                              .then((res) => {
                                if (res.status == 200) {
                                  setshowPopup(true);
                                }
                              });
                          }
                        }
                      });
                    } else {
                      setshowsuccpopup(true);
                    }
                  }
                });
            });
        } else {
          axios
            .put(
              process.env.REACT_APP_SERVER_URL + "/api/user/me",
              {
                username: username,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("jwt"),
                },
              }
            )
            .then((res) => {
              if (res.status == 200) {
                if (Email != OrginalEmail) {
                  axios(process.env.REACT_APP_PROXY_URL + "/checkEmail", {
                    headers: {
                      email: Email,
                    },
                  }).then((res) => {
                    if (res.status == 200) {
                      if (res.data.length > 0) {
                        setErrorMesage("Email not available");
                      } else {
                        axios
                          .post(
                            process.env.REACT_APP_PROXY_URL + "/sendEmail",
                            {
                              jwt: localStorage.getItem("jwt"),
                              email: Email,
                            }
                          )
                          .then((res) => {
                            if (res.status == 200) {
                              setshowPopup(true);
                            }
                          });
                      }
                    }
                  });
                } else {
                  setshowsuccpopup(true);
                }
              }
            });
        }
      } else {
        setErrorMesage("Email format is invalid");
      }
    } else {
      setErrorMesage("Please fill all fields");
    }
  }

  function HandleImgChange(e) {
    if (e.target.files.length > 0) {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e) {
        document.getElementById("profile").src = this.result;
      };
    }
  }

  function updatePass() {
    if (
      CurrentPass.length > 0 &&
      NewPass.length > 0 &&
      ConfirmPass.length > 0
    ) {
      if (checkPassword(NewPass)) {
        if (NewPass != CurrentPass) {
          if (NewPass == ConfirmPass) {
            fetch(
              process.env.REACT_APP_SERVER_URL + "/api/auth/change-password",
              {
                method: "POST",
                headers: {
                  "Content-type": "Application/json",
                  Authorization: "Bearer " + localStorage.getItem("jwt"),
                },
                body: JSON.stringify({
                  currentPassword: CurrentPass,
                  password: NewPass,
                  passwordConfirmation: ConfirmPass,
                }),
              }
            )
              .then((res) => res.json())
              .then((data) => {
                if (data?.error) {
                  setPassErrorMessage(data.error.message);
                } else {
                  setshowsuccpopup(true);
                }
              });
          } else {
            setPassErrorMessage(
              "Confirm Password does't match the new password"
            );
          }
        } else {
          setPassErrorMessage("New password can't be same as current password");
        }
      } else {
        setPassErrorMessage(
          "Password at least has 8 characters, one lower and upper case letters, one number and one special character"
        );
      }
    } else {
      setPassErrorMessage("Please fill all fields");
    }

    // axios
    //   .post(
    //     process.env.REACT_APP_SERVER_URL + "/api/auth/change-password",
    //     {
    //       currentPassword: CurrentPass,
    //       password: NewPass,
    //       passwordConfirmation: ConfirmPass,
    //     },
    //     {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("jwt"),
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     if (res.status == 200) {
    //       console.log(res.data);
    //     }
    //   });
  }

  function HandelEmailVerfication() {
    fetch(process.env.REACT_APP_PROXY_URL + "/verify", {
      method: "POST",
      headers: {
        "Content-type": "Application/json",
      },
      body: JSON.stringify({
        code: Code,
        jwt: localStorage.getItem("jwt"),
      }),
    })
      .then((res) => res.json())

      .then((data) => {
        if (data.status != "200") {
          setPopupErrorMesage(data.message);
        } else {
          axios
            .put(
              process.env.REACT_APP_SERVER_URL + "/api/user/me",
              {
                email: Email,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("jwt"),
                },
              }
            )
            .then((resposne) => {
              if (resposne.status == 200) {
                setshowPopup(false);
                setshowsuccpopup(true);
              }
            });
        }
      });

    // axios
    //   .post(process.env.REACT_APP_PROXY_URL + "/verify", {
    //     code: Code,
    //     jwt: localStorage.getItem("jwt"),
    //   })
    //   .then((res) => {
    //     if (res.status == 200) {
    //       axios
    //         .put(
    //           process.env.REACT_APP_SERVER_URL + "/api/user/me",
    //           {
    //             email: Email,
    //           },
    //           {
    //             headers: {
    //               Authorization: "Bearer " + localStorage.getItem("jwt"),
    //             },
    //           }
    //         )
    //         .then((resposne) => {
    //           console.log("resss");
    //           if (resposne.status == 200) {
    //             setshowPopup(false);
    //           }
    //         });
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err.resposne);
    //   });
  }

  useLayoutEffect(() => {
    document.title = "User Profile | PlaystoEarn";
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", "User");
  }, []);

  if (isLoggedin) {
    return (
      <div>
        <PageTitle heading="User Profile" title="User" />

        {showPopup && (
          <div className="popup_cont">
            <div
              className="popup"
              style={{ background: theme == "light" ? "white" : "black" }}
            >
              <div>
                <img
                  style={{ width: "100px", height: "100px" }}
                  src={Mail}
                  alt=""
                />
              </div>

              <div style={{ textAlign: "center" }}>
                <div>Verification code sent to:</div>
                <div>{Email}</div>
              </div>

              <div>
                <div className="form-group d-flex s1">
                  <input
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                    type="number"
                    style={{ textAlign: "center" }}
                    className="form-control"
                    placeholder="Enter Code"
                  />
                </div>
              </div>

              <div style={{ display: "flex", gap: "10px", width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <div
                    onClick={() => {
                      setshowPopup(false);
                    }}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      backgroundColor: "red",
                    }}
                    className="btn-action"
                  >
                    Close
                  </div>
                </div>

                <div style={{ width: "100%" }}>
                  <div
                    onClick={() => {
                      HandelEmailVerfication();
                    }}
                    style={{ width: "100%", textAlign: "center" }}
                    className="btn-action"
                  >
                    Verify
                  </div>
                </div>
              </div>
              {PopupErrorMesage.length > 0 && (
                <div
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    fontSize: "17px",
                    marginTop: "10px",
                    marginBottom: "20px",
                  }}
                >
                  {PopupErrorMesage}
                </div>
              )}
            </div>
          </div>
        )}

        {showsuccpopup && (
          <div className="popup_cont">
            <div
              className="popup"
              style={{ background: theme == "light" ? "white" : "black" }}
            >
              <div>
                <img
                  style={{ width: "200px", height: "150px" }}
                  src={Check}
                  alt=""
                />
              </div>

              <div>
                <div>Account Updated successfully</div>
              </div>

              <div style={{ width: "100%" }}>
                <div
                  onClick={() => {
                    window.location.reload();
                  }}
                  style={{ width: "100%", textAlign: "center" }}
                  className="btn-action"
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        )}

        <section className="user-profile flat-tabs">
          <div className="container">
            <div className="row">
              <Tabs>
                <TabList>
                  <div className="user-info center">
                    <div className="avt">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="imgInp"
                        required
                        onChange={(e) => {
                          HandleImgChange(e);
                          setFiles(e.target.files);
                        }}
                      />
                      <img
                        id="profile"
                        src={
                          img ? process.env.REACT_APP_PROXY_URL + img : Profile
                        }
                        alt="profile image"
                      />
                    </div>
                    <h6 className="name">@{User?.username}</h6>
                    <p>{User?.email}</p>
                  </div>
                  {dataCoinTab.map((idx) => (
                    <Tab key={idx.id}>
                      <h6 className="fs-16">
                        <i className={`fa ${idx.icon}`}></i>
                        {idx.title}
                      </h6>
                    </Tab>
                  ))}
                </TabList>

                <TabPanel>
                  <div className="content-inner profile">
                    <form action="#">
                      <h4>User Profile</h4>

                      <div className="form-group d-flex s1">
                        <input
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          type="email"
                          className="form-control"
                          value={Email}
                          placeholder="Enter Email"
                        />
                        <input
                          onChange={(e) => {
                            setusername(e.target.value);
                          }}
                          type="text"
                          className="form-control"
                          value={username}
                          placeholder="Enter Username"
                        />
                      </div>

                      <div
                        onClick={() => {
                          if (
                            OrginalEmail != Email ||
                            OrginalUsername != username ||
                            Files?.length > 0
                          ) {
                            UpdateProfile(User?.id);
                          }
                        }}
                        type="submit"
                        className={
                          OrginalEmail != Email ||
                          OrginalUsername != username ||
                          Files?.length > 0
                            ? "btn-action"
                            : "btn_disabled"
                        }
                      >
                        Update Profile
                      </div>
                    </form>
                    <div
                      style={{
                        fontWeight: "bold",
                        color: "red",
                        fontSize: "17px",
                        marginTop: "10px",
                        marginBottom: "20px",
                      }}
                    >
                      {ErrorMesage}
                    </div>
                  </div>
                </TabPanel>

                <TabPanel>
                  <div className="content-inner profile change-pass">
                    <h4>Change Password</h4>

                    <form action="#">
                      <div className="form-group">
                        <div style={{ width: "100%" }}>
                          <label>
                            Current Password<span>*</span>
                          </label>
                          <input
                            onChange={(e) => {
                              setCurrentPass(e.target.value);
                            }}
                            type="text"
                            style={{ width: "100%" }}
                            className="form-control"
                            placeholder="Old Password"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="password_full">
                          <label>
                            New Password<span>*</span>
                          </label>
                          <input
                            onChange={(e) => {
                              setNewPass(e.target.value);
                            }}
                            type="password"
                            className="form-control"
                            placeholder="New Password"
                          />
                        </div>
                        <div className="password_full">
                          <label>
                            Confirm Password<span>*</span>
                          </label>
                          <input
                            onChange={(e) => {
                              setConfirmPass(e.target.value);
                            }}
                            type="password"
                            className="form-control"
                            placeholder="Confirm Password"
                          />
                        </div>
                      </div>
                    </form>
                    <div
                      onClick={updatePass}
                      type="submit"
                      className="btn-action"
                    >
                      Change Password
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        color: "red",
                        fontSize: "17px",
                        marginTop: "10px",
                        marginBottom: "20px",
                      }}
                    >
                      {PassErrorMessage}
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </section>
      </div>
    );
  } else if (isLoggedin == false) {
    window.location.href = "/login";
    return <></>;
  }
}

export default UserProfile;
