import React, { useLayoutEffect } from "react";
import PageTitle from "../components/pagetitle";
import PageHead from "../components/pagetitle/PageHead";

function Privacy() {
  useLayoutEffect(() => {
    document.title = "Privacy Policy | PlaystoEarn";
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", "Privacy Policy");
  }, []);

  return (
    <div>
      <PageTitle heading="Privacy Policy" title="Privacy Policy" />

      <section className="section__space">
        <div className="container terms_cont">
          <div className="terms-area">
            <div class="terms__single">
              <p>
                This Privacy Policy describes how PlaystoEarn ("we," "us," or
                "our) collects, uses, and shares information about you when you
                use our website, platform, and services.
              </p>

              <h4>Information We Collect</h4>

              <p>
                We collect information about you when you access or use
                PlaystoEarn, including:
              </p>

              <ul>
                <li>
                  Information you provide to us, such as your name, email
                  address, and other contact details.
                </li>
                <li>
                  Information we collect automatically when you use PlaystoEarn,
                  such as your IP address, device information, and browsing
                  activity.
                </li>
                <li>
                  Information we receive from third parties, such as social
                  media platforms and payment processors.
                </li>
              </ul>

              <hr />

              <h4>How We Use Your Information</h4>
              <p>
                We use the information we collect for various purposes,
                including to:
              </p>

              <ul>
                <li>
                  Provide, maintain, and improve PlaystoEarn and our services.
                </li>
                <li>
                  Communicate with you about your account and our services.
                </li>
                <li>
                  Personalize your experience on PlaystoEarn and provide
                  targeted advertisements and content.
                </li>
                <li>
                  Analyze usage trends and preferences to enhance the user
                  experience.
                </li>
                <li>
                  Comply with legal obligations and enforce our rights and
                  agreements.
                </li>
              </ul>

              <h4>Information Sharing and Disclosure</h4>
              <p>
                We may share your information with third parties for various
                purposes, including:
              </p>

              <ul>
                <li>
                  With service providers and business partners who assist us in
                  operating PlaystoEarn and providing our services.
                </li>
                <li>
                  With law enforcement authorities, government agencies, or
                  other third parties as required by law or to protect our
                  rights and interests.
                </li>
                <li>
                  In connection with a merger, acquisition, or sale of assets,
                  in which case your information may be transferred to another
                  entity.
                </li>
                <li>With your consent or at your direction.</li>
              </ul>

              <h4>Cookies and Similar Technologies</h4>
              <p>
                We use cookies and similar technologies to collect information
                about your usage of PlaystoEarn and to provide certain features
                and functionality. You can manage your cookie preferences
                through your browser settings.
              </p>

              <h4>Data Security</h4>
              <p>
                We take reasonable measures to protect your information from
                unauthorized access, disclosure, alteration, or destruction.
                However, no method of transmission over the internet or
                electronic storage is completely secure.
              </p>

              <h4>Your Choices and Rights</h4>
              <p>
                You may have certain rights and choices regarding the
                collection, use, and sharing of your information. These may
                include the right to access, update, or delete your information,
                as well as the right to object to certain processing activities.
              </p>

              <h4>Children's Privacy</h4>
              <p>
                PlaystoEarn is not intended for use by children under the age of
                13. We do not knowingly collect personal information from
                children under 13 years of age.
              </p>

              <h4>Changes to This Privacy Policy</h4>
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or for other operational, legal, or
                regulatory reasons. We will notify you of any material changes
                by posting the updated Privacy Policy on our website or through
                other appropriate means.
              </p>

              <h4>Contact Us</h4>
              <p>
                If you have any questions or concerns about this Privacy Policy
                or our privacy practices, please contact us at
                support@playstoearn.com
              </p>

              <p>Last updated: Friday, April 10, 2024</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Privacy;
