import React, { useEffect, useLayoutEffect, useState } from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import dataBlog from "../assets/fake-data/data-blog";

import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

import { Link, useNavigate, useParams } from "react-router-dom";
import Sale01 from "../components/sale/Sale01";
import PageTitle from "../components/pagetitle";
import axios from "axios";
import PageHead from "../components/pagetitle/PageHead";

import { Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

function BlogCategory(props) {
  const [isOpen, setOpen] = useState(false);

  const [dataCrytoTab] = useState([
    {
      id: 1,
      title: "View All",
    },
    {
      id: 2,
      title: "Learn & Earn",
    },
    {
      id: 3,
      title: "Metaverse",
    },
    {
      id: 4,
      title: "Energy",
    },
    {
      id: 5,
      title: "NFT",
    },
    {
      id: 6,
      title: "Gaming",
    },
    {
      id: 7,
      title: "Music",
    },
  ]);

  const { id } = useParams();

  const [Data, setData] = useState([]);
  const [FilteredData, setFilteredData] = useState(null);
  const [SelectedCategory, setSelectedCategory] = useState("*");
  const [Categories, setCategories] = useState([]);
  const [SearchText, setSearchText] = useState("");
  const [ShowPage, setShowPage] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;
    axios(process.env.REACT_APP_SERVER_URL + "/api/blogs?populate=*").then(
      (res) => {
        if (res.status == 200) {
          setData(res.data.data);
          let filteredData = res.data.data.filter((ele) => {
            return ele.attributes.categories.data.some(
              (cate) =>
                cate.attributes.title.toLowerCase() ==
                id.toLowerCase().replaceAll("-", " ")
            );
          });
          if (filteredData.length > 0) {
            if (window.location.href[window.location.href.length - 1] == "/")
              window.location.href = window.location.href.slice(0, -1);
            setShowPage(true);
          } else {
            navigate("/404");
          }
          setFilteredData(filteredData);
        }
      }
    );
    axios(
      process.env.REACT_APP_SERVER_URL +
        "/api/categories?filters[title][$eq]=test"
    ).then((res) => {
      if (res.status == 200) {
        setCategories(res.data.data);
      }
    });
  }, [id]);

  const HandleCategoryChange = (category) => {
    let filteredData = [];
    setSelectedCategory(category);
    if (category != "*") {
      filteredData = Data.filter((ele) => {
        return ele.attributes.categories.data.some(
          (cate) => cate.attributes.title == category
        );
      });
    } else {
      filteredData = Data;
    }

    if (SearchText.length > 0) {
      let searchedData = filteredData.filter((ele) =>
        ele.attributes.title.includes(SearchText)
      );

      setFilteredData(searchedData);
    } else {
      setFilteredData(filteredData);
    }
  };

  const HandleCategoryChangeWithSearch = (category, search = "") => {
    let filteredData = [];
    setSelectedCategory(category);
    if (category != "*") {
      filteredData = Data.filter((ele) => {
        return ele.attributes.categories.data.some(
          (cate) => cate.attributes.title == category
        );
      });
    } else {
      filteredData = Data;
    }

    if (search.length > 0) {
      let searchedData = filteredData.filter((ele) =>
        ele.attributes.title.includes(search)
      );

      setFilteredData(searchedData);
    } else {
      setFilteredData(filteredData);
    }
  };

  const HandleSearch = (e) => {
    let tempval = e.target.value;
    setSearchText(tempval);

    HandleCategoryChangeWithSearch(SelectedCategory, e.target.value);
  };

  useLayoutEffect(() => {
    document.title = "Blog Category | PlaystoEarn";
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", "Blog Category");
  }, []);

  return (
    <div style={{ flex: "1" }}>
      <PageTitle heading="Blog Category" title="Blog Category" />

      <section className="blog-grid" style={{ paddingTop: "20px " }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Tabs>
                <div className="content-inner row" style={{ marginTop: "5vh" }}>
                  {FilteredData != null
                    ? FilteredData.map((ele) => {
                        let date = new Date(ele.attributes.createdAt);
                        let month = date.toLocaleString("default", {
                          month: "short",
                        });
                        let day = date.getDate();
                        let year = date.getFullYear();

                        let full_date = `${month} ${day}, ${year}`;

                        return (
                          <Link
                            to={"/blog/" + ele.attributes.slug}
                            key={ele.id}
                            className="col-md-4"
                          >
                            <div className="blog-box">
                              <div className="box-image">
                                <img
                                  style={{
                                    height: "300px",
                                    objectFit: "cover",
                                  }}
                                  src={
                                    process.env.REACT_APP_SERVER_URL +
                                    ele.attributes.thumbnail.data.attributes.url
                                  }
                                  alt="Rockie"
                                />
                              </div>

                              <div
                                className="box-content"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    to="#"
                                    className="category btn-action"
                                    style={{ width: "fit-content" }}
                                  >
                                    {
                                      ele.attributes.categories.data[0]
                                        .attributes.title
                                    }
                                  </div>
                                  <div
                                    to="#"
                                    className="time"
                                    style={{ color: "#777e90" }}
                                  >
                                    {full_date}
                                  </div>
                                </div>

                                <div
                                  to="#"
                                  className="title"
                                  style={{ marginTop: "20px" }}
                                >
                                  {ele.attributes.title}
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                      })
                    : Data.map((ele) => {
                        let date = new Date(ele.attributes.createdAt);
                        let month = date.toLocaleString("default", {
                          month: "short",
                        });
                        let day = date.getDate();
                        let year = date.getFullYear();

                        let full_date = `${month} ${day}, ${year}`;

                        return (
                          <Link
                            to={"/blog/" + ele.attributes.slug}
                            key={ele.id}
                            className="col-md-4"
                          >
                            <div className="blog-box">
                              <div className="box-image">
                                <img
                                  style={{
                                    height: "300px",
                                    objectFit: "cover",
                                  }}
                                  src={
                                    process.env.REACT_APP_SERVER_URL +
                                    ele.attributes.thumbnail.data.attributes.url
                                  }
                                  alt="Rockie"
                                />
                              </div>

                              <div
                                className="box-content"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Link
                                    to="#"
                                    className="category btn-action"
                                    style={{ width: "fit-content" }}
                                  >
                                    {
                                      ele.attributes.categories.data[0]
                                        .attributes.title
                                    }
                                  </Link>
                                  <Link
                                    to="#"
                                    className="time"
                                    style={{ color: "#777e90" }}
                                  >
                                    {full_date}
                                  </Link>
                                </div>

                                <Link
                                  to="#"
                                  className="title"
                                  style={{ marginTop: "20px" }}
                                >
                                  {ele.attributes.title}
                                </Link>
                              </div>
                            </div>
                          </Link>
                        );
                      })}

                  <div className="col-md-12"></div>
                </div>
              </Tabs>
            </div>
          </div>
        </div>

        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="i7EMACWuErA"
          onClose={() => setOpen(false)}
        />
      </section>
    </div>
  );
}

export default BlogCategory;
