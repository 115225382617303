import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import img1 from "../../assets/images/coin/btc.png";
import img2 from "../../assets/images/coin/eth.png";
import img3 from "../../assets/images/coin/tet.png";
import img4 from "../../assets/images/coin/bnb.png";

import "./styles.scss";

import { Link, useParams } from "react-router-dom";
import Loader from "../Loader";
import axios from "axios";

function CryptoMarket({
  Coins,
  Platforms,
  isLoading,
  RatingClassColor,
  Columns,
  BlockChains,
  ismargin,
  TableSlug,
  theme,
  is_earn,
}) {
  const [dataCrytoTab] = useState([
    {
      id: 1,
      title: "Crypto",
    },
    {
      id: 2,
      title: "DeFi",
    },
    {
      id: 3,
      title: "BSC",
    },
    {
      id: 4,
      title: "NFT",
    },
    {
      id: 5,
      title: "Metaverse",
    },
    {
      id: 6,
      title: "Polkadot",
    },
    {
      id: 7,
      title: "Solana",
    },
    {
      id: 8,
      title: "Opensea",
    },
    {
      id: 9,
      title: "Makersplace",
    },
  ]);

  return (
    <section
      style={{ marginTop: !ismargin && "0px" }}
      className="crypto"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 mobile_no_padding">
            <div className="crypto__main " style={{ padding: "30px" }}>
              <Tabs style={{ marginTop: "0px" }}>
                {dataCrytoTab.map((data) => {
                  return (
                    <TabPanel key={data.id}>
                      {isLoading ? (
                        <center>
                          <span
                            style={{
                              border: "5px solid #3772ff",
                              borderBottomColor: "transparent",
                            }}
                            class="loader"
                          ></span>
                        </center>
                      ) : (
                        <div
                          className="content-inner"
                          style={{ justifyContent: "space-evenly" }}
                        >
                          {Coins.slice(0, 4).map((data) => {
                            console.log("markerts", is_earn);
                            if (
                              Columns.filter(
                                (e) => e.attributes.name == "Trust Rating"
                              ).length > 0
                            ) {
                              return (
                                <a
                                  href={
                                    "/" + TableSlug + "/" + data.attributes.slug
                                  }
                                  key={data.id}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    // alignItems: "center",
                                    justifyContent: "center",
                                    padding: "40px",
                                  }}
                                  className={`crypto-box active coins_link`}
                                >
                                  {data.attributes.is_ad == true && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "10px",
                                        fontSize: "13px",
                                        width: "50px",
                                      }}
                                      className="rating blue"
                                    >
                                      Ad
                                    </div>
                                  )}
                                  <div className="top">
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "5px",
                                        alignItems: "center",
                                      }}
                                      to="#"
                                    >
                                      <img
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                          borderRadius: "50%",
                                        }}
                                        src={
                                          data.attributes.icon_url?.length > 0
                                            ? data.attributes.icon_url
                                            : process.env.REACT_APP_SERVER_URL +
                                              data.attributes.icon.data
                                                .attributes.url
                                        }
                                        alt="rockie"
                                      />
                                      <div>
                                        <div
                                          style={{
                                            color:
                                              theme == "light"
                                                ? "black"
                                                : "white",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {data.attributes.name}
                                        </div>
                                        <div
                                          className="unit"
                                          style={{
                                            fontSize: "12px",
                                            color:
                                              theme == "light"
                                                ? "black"
                                                : "white",
                                          }}
                                        >
                                          {data.attributes.ticker}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <h6
                                      className="price"
                                      style={{ marginBottom: "0px" }}
                                    >
                                      USD{" "}
                                      {new Intl.NumberFormat("en-US", {
                                        currency: "USD",
                                      }).format(
                                        parseFloat(
                                          data.attributes.price
                                        ).toFixed(2)
                                      ) == "NaN"
                                        ? "-"
                                        : new Intl.NumberFormat("en-US", {
                                            currency: "USD",
                                          }).format(
                                            parseFloat(
                                              data.attributes.price
                                            ).toFixed(2)
                                          )}
                                    </h6>

                                    <div
                                      className="bottom"
                                      style={{
                                        gap: "5px",

                                        flexDirection: "column",
                                      }}
                                    >
                                      <p
                                        style={{ fontSize: "17px" }}
                                        className={RatingClassColor(
                                          parseFloat(
                                            data.attributes.trust_rating
                                          )
                                        )}
                                      >
                                        {data.attributes.trust_rating}
                                      </p>
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      className="bottom"
                                      style={{
                                        gap: "5px",

                                        marginTop: "15px",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: "14px",
                                          color:
                                            theme == "light"
                                              ? "black"
                                              : "white",
                                        }}
                                      >
                                        Volume
                                      </p>

                                      <p
                                        style={{
                                          fontSize: "17px",
                                          color:
                                            theme == "light"
                                              ? "black"
                                              : "white",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {" "}
                                        {new Intl.NumberFormat("en-US", {
                                          currency: "USD",
                                        })
                                          .format(
                                            parseFloat(
                                              data.attributes.volume_24
                                            )
                                          )
                                          .split(".")[0] == "NaN"
                                          ? "-"
                                          : new Intl.NumberFormat("en-US", {
                                              currency: "USD",
                                            })
                                              .format(
                                                parseFloat(
                                                  data.attributes.volume_24
                                                )
                                              )
                                              .split(".")[0]}
                                      </p>
                                    </div>

                                    <div
                                      className="bottom"
                                      style={{
                                        gap: "5px",

                                        marginTop: "15px",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: "14px",
                                          color:
                                            theme == "light"
                                              ? "black"
                                              : "white",
                                        }}
                                      >
                                        Market Cap
                                      </p>

                                      <p>
                                        <p
                                          style={{
                                            fontSize: "17px",
                                            color:
                                              theme == "light"
                                                ? "black"
                                                : "white",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {" "}
                                          {new Intl.NumberFormat("en-US", {
                                            currency: "USD",
                                          })
                                            .format(
                                              parseFloat(
                                                data.attributes.market_cap
                                              )
                                            )
                                            .split(".")[0] == "NaN"
                                            ? "-"
                                            : new Intl.NumberFormat("en-US", {
                                                currency: "USD",
                                              })
                                                .format(
                                                  parseFloat(
                                                    data.attributes.market_cap
                                                  )
                                                )
                                                .split(".")[0]}
                                        </p>
                                      </p>
                                    </div>
                                  </div>
                                </a>
                              );
                            } else {
                              return (
                                <a
                                  href={
                                    "/" + TableSlug + "/" + data.attributes.slug
                                  }
                                  key={data.id}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    // alignItems: "center",
                                    justifyContent: "center",
                                    padding: "40px",
                                    gap: "10px",
                                  }}
                                  className={`crypto-box active`}
                                >
                                  {data.attributes.is_ad_earn == true && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "10px",
                                        fontSize: "13px",
                                        width: "50px",
                                      }}
                                      className="rating blue"
                                    >
                                      Ad
                                    </div>
                                  )}

                                  <div className="top">
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "5px",
                                        alignItems: "center",
                                      }}
                                      to="#"
                                    >
                                      <img
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                          borderRadius: "50%",
                                        }}
                                        src={
                                          data.attributes.icon_url?.length > 0
                                            ? data.attributes.icon_url
                                            : process.env.REACT_APP_SERVER_URL +
                                              data.attributes.icon.data
                                                .attributes.url
                                        }
                                        alt="rockie"
                                      />
                                      <div>
                                        <div
                                          style={{
                                            color:
                                              theme == "light"
                                                ? "black"
                                                : "white",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {data.attributes.name}
                                        </div>
                                        <div
                                          className="unit"
                                          style={{ fontSize: "12px" }}
                                        >
                                          {data.attributes.ticker}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {Columns.filter(
                                    (e) => e.attributes.name == "BlockChain"
                                  ).length > 0 ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "5px",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <p
                                        className={RatingClassColor(
                                          parseFloat(
                                            data.attributes.earnings_rating
                                          ) == NaN
                                            ? "-"
                                            : parseFloat(
                                                data.attributes.earnings_rating
                                              )
                                        )}
                                      >
                                        {data.attributes.earnings_rating}
                                      </p>

                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "5px",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        {data.attributes.blockchains.data.map(
                                          (chain) => {
                                            return (
                                              <div>
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                  }}
                                                  src={
                                                    process.env
                                                      .REACT_APP_SERVER_URL +
                                                    BlockChains[
                                                      chain.attributes.title
                                                    ]
                                                  }
                                                  alt="rockie"
                                                />
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "5px",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <p
                                        className={RatingClassColor(
                                          parseFloat(
                                            data.attributes.earnings_rating
                                          ) == NaN
                                            ? "-"
                                            : parseFloat(
                                                data.attributes.earnings_rating
                                              )
                                        )}
                                      >
                                        {data.attributes.earnings_rating}
                                      </p>

                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "5px",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        {data.attributes.platforms.data.map(
                                          (chain) => {
                                            return (
                                              <div>
                                                <img
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                  }}
                                                  src={
                                                    process.env
                                                      .REACT_APP_SERVER_URL +
                                                    Platforms[
                                                      chain.attributes.title
                                                    ]
                                                  }
                                                  alt="rockie"
                                                />
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {/* <div
                                    className="bottom"
                                    style={{
                                      gap: "5px",
                                      alignItems: "center",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <p>Earning Rating</p>

                                    <p
                                      className={RatingClassColor(
                                        parseFloat(
                                          data.attributes.earnings_rating
                                        )
                                      )}
                                    >
                                      {data.attributes.earnings_rating}
                                    </p>
                                  </div> */}
                                </a>
                              );
                            }
                          })}
                        </div>
                      )}
                    </TabPanel>
                  );
                })}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CryptoMarket;
