import React, { useState } from "react";
import PropTypes from "prop-types";

import { Navigation, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import feedback1 from '../../assets/images/avt/feedback1.png';
import feedback2 from '../../assets/images/avt/feedback2.png';

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

Testimonial01.propTypes = {};

function Testimonial01(props) {
  const { data } = props;

  const [dataBlock] = useState({
    title: "Gamers love what we do",
    desc: "Find the most profitable gaming opportunities",
    text: "PlaystoEarn is your primary source for Web3 gaming information, whether you are an investor or player!",
  });

  return (
    <section className="testimonials">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-md-12">
            <div className="block-text">
              <h3 className="heading">{dataBlock.title}</h3>
              <h6 className="fs-20">{dataBlock.desc}</h6>
              <p>{dataBlock.text}</p>

              <div className="swiper swiper-thumb1">
                <div className="swiper-wrapper list-img">
                  <div className="swiper-slide">
                    <img src={feedback1} alt="feedback1" />
                  </div>
                  <div className="swiper-slide">
                    <img src={feedback2} alt="feedback2" />
                  </div>
                </div>
              </div>
              <div className="couter">
                <h6>14+</h6>
                <p className="title">Customer Reviews</p>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <Swiper
              modules={[Navigation, Scrollbar, A11y]}
              spaceBetween={0}
              slidesPerView={1}
              className="swiper-testimonial-1"
            >
              {data.map((idx) => (
                <SwiperSlide key={idx.id}>
                  <div className="testimonials-box">
                    <span className="icon-quote"></span>
                    <h6 className="text">{idx.text}</h6>
                    <div className="bottom">
                      <div className="info">
                        <img src={idx.avt} alt="Rockie" />
                        <div className="content">
                          <h6 className="name">{idx.name}</h6>
                          <p className="position">{idx.position}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonial01;
