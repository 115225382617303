import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import Sale01 from "../components/sale/Sale01";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import PageTitle from "../components/pagetitle";
import PageHead from "../components/pagetitle/PageHead";

import { Link, useNavigate } from "react-router-dom";
import img from "../assets/images/icon/qrcode.png";
import axios from "axios";
import { LoginContext } from "../components/LoginContext";
import Check from "../assets/images/icon/check.png";

function ForgetPassword(props) {
  const [Username, setUsername] = useState(null);
  const [Password, setPassword] = useState(null);
  const [showPopup, setshowPopup] = useState(false);
  const [isDisabled, setisDisabled] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const { isLoggedin } = useContext(LoginContext);

  const navigate = useNavigate();

  function HandleLogin() {
    if (Username?.length > 0) {
      if (
        Username.toLowerCase().match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        axios
          .post(
            process.env.REACT_APP_SERVER_URL + "/api/auth/forgot-password",
            {
              email: Username, // user's email
            }
          )
          .then((response) => {
            setshowPopup(true);
            console.log("Your user received an email");
          })
          .catch((error) => {
            setErrorMessage("An error occurred");
          });
      } else {
        setErrorMessage("Email format is invalid");
      }
    } else {
      setErrorMessage("Please fill all fields");
    }

    // axios
    //   .post(process.env.REACT_APP_SERVER_URL + "/api/auth/local", {

    //   })
    //   .then((res) => {
    //     if (res.status == 200) {
    //       console.log("status", res.status);
    //       console.log(res.data);
    //       // localStorage.setItem("jwt", res.data.jwt);
    //       // window.location.href = "/";
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("err", err);
    //   });
  }

  useLayoutEffect(() => {
    document.title = "Password Reset | PlaystoEarn";
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", "Password Reset");
  }, []);

  if (!isLoggedin) {
    return (
      <div>
        <PageTitle heading="Password Reset" title="Password Reset" />

        {showPopup && (
          <div className="popup_cont">
            <div className="popup">
              <div>
                <img
                  style={{ width: "100px", height: "100px" }}
                  src={Check}
                  alt=""
                />
              </div>

              <div>
                <div>Reset link has been sent, please check your inbox</div>
              </div>

              <div style={{ width: "100%" }}>
                <div
                  onClick={() => {
                    setshowPopup(false);
                  }}
                  style={{ width: "100%", textAlign: "center" }}
                  className="btn-action"
                >
                  ok
                </div>
              </div>
            </div>
          </div>
        )}

        <section className="register login">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="block-text center">
                  <h3 className="heading">Forgot your password?</h3>
                  <p className="desc fs-20">
                    Enter your email to send a reset link
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <Tabs>
                  <TabPanel>
                    <div className="content-inner">
                      <form>
                        <div className="form-group">
                          <label for="exampleInputEmail1">Email</label>
                          <input
                            style={{
                              border:
                                Username == null || Username?.length > 0
                                  ? "1px solid #ced4da"
                                  : "1px solid red",
                            }}
                            onChange={(e) => {
                              setUsername(e.target.value);
                            }}
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Please fill in the Email form."
                          />
                        </div>

                        <div
                          style={{
                            fontWeight: "bold",
                            color: "red",
                            fontSize: "17px",
                            marginTop: "10px",
                            marginBottom: "20px",
                          }}
                        >
                          {ErrorMessage}
                        </div>

                        <div
                          onClick={HandleLogin}
                          type="submit"
                          className="btn-action"
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Send
                        </div>
                        <div className="bottom">
                          <p>Not a member?</p>
                          <Link to="/register">Register</Link>
                        </div>
                      </form>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="content-inner">
                      <form>
                        <div className="form-group">
                          <label for="exampleInputEmail1">Mobile Phone</label>
                          <div>
                            <select
                              className="form-control"
                              id="exampleFormControlSelect1"
                            >
                              <option>+1</option>
                              <option>+84</option>
                              <option>+82</option>
                              <option>+32</option>
                            </select>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Your Phone number"
                            />
                          </div>
                        </div>
                        <div className="form-group s1">
                          <label>Password </label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Please enter a password."
                          />
                        </div>

                        <div className="form-group form-check">
                          <div>
                            <input
                              type="checkbox"
                              className="form-check-input"
                            />
                            <label className="form-check-label">
                              Remember Me
                            </label>
                          </div>
                          <p>Forgot Password?</p>
                        </div>

                        <button type="submit" className="btn-action">
                          Login
                        </button>
                        <div className="bottom">
                          <p>Not a member?</p>
                          <Link to="/register">Register</Link>
                        </div>
                      </form>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else if (isLoggedin == true) {
    window.location.href = "/";
    return <div></div>;
  }
}

export default ForgetPassword;
