import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { Link } from "react-router-dom";

BlogTitle.propTypes = {
  title: PropTypes.string,
};

function BlogTitle(props) {
  const { title, heading, blog } = props;

  return (
    <section className="page-title">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h3 className="heading">{heading}</h3>
          </div>
          <div className="col-md-6">
            <ul
              className="breadcrumb"
              style={{ display: "flex", flexWrap: "nowrap" }}
            >
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <p className="fs-18">/</p>
              </li>
              <li>
                <Link to={"/blog"} className="fs-18">
                  {title}
                </Link>
              </li>
              <li>
                <p className="fs-18">/</p>
              </li>
              <li style={{ overflow: "hidden" }}>
                <p
                  className="fs-18"
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {blog}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogTitle;
