import React from "react";

function CountdownDisplay(props) {
  return (
    <div style={{ display: "flex" }}>
      <div>
        <span>{props.days}</span>
        <span> D</span>
      </div>
    </div>
  );
}

export default CountdownDisplay;
