import React, { useState } from "react";
import PropTypes from "prop-types";

import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

import { Link } from "react-router-dom";

function BlogCoin({ BlogData }) {
  const [dataBlock] = useState({
    heading: "Learn And Earn",
    desc: "Stacks is a production-ready library of stackable content blocks built in React Native.",
  });
  const [isOpen, setOpen] = useState(false);

  return (
    <section className="blog" style={{ padding: "15px", marginTop: "5vh" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="block-text ">
              <h1 className="heading">Recent Blogs</h1>
            </div>
          </div>

          {BlogData.map((ele) => {
            let date = new Date(ele.attributes.createdAt);
            let month = date.toLocaleString("default", {
              month: "short",
            });
            let day = date.getDate();
            let year = date.getFullYear();

            let full_date = `${month} ${day}, ${year}`;

            return (
              <Link
                to={"/blog/" + ele.attributes.slug}
                key={ele.id}
                className="col-md-4"
              >
                <div className="blog-box">
                  <div className="box-image">
                    <img
                      style={{
                        height: "300px",
                        objectFit: "cover",
                      }}
                      src={
                        process.env.REACT_APP_SERVER_URL +
                        ele.attributes.thumbnail.data.attributes.url
                      }
                      alt="Rockie"
                    />
                  </div>

                  <div
                    className="box-content"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        to="#"
                        className="category btn-action"
                        style={{ width: "fit-content" }}
                      >
                        {ele?.attributes?.categories?.data[0]?.attributes?.title
                          ? ele?.attributes?.categories?.data[0]?.attributes
                              ?.title
                          : "NO TAG"}
                      </div>
                      <div to="#" className="time" style={{ color: "#777e90" }}>
                        {full_date}
                      </div>
                    </div>

                    <div to="#" className="title" style={{ marginTop: "20px" }}>
                      {ele.attributes.title}
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="i7EMACWuErA"
        onClose={() => setOpen(false)}
      />
    </section>
  );
}

export default BlogCoin;
