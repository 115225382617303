

import feedback1 from '../images/avt/feedback1.png';
import feedback2 from '../images/avt/feedback2.png';
import feedback3 from '../images/avt/feedback3.png';



const dataTestimonial = [
    {
        id: 1,
        text: " “Great website to find the most profitable games in the genres I like! I can easily hop in and out of games based on how much they pay.” ",
        avt: feedback1,
        name: 'Johnny',
        position: 'Web3 Gaming Enthusiast',
    },
    {
        id: 2,
        text: " “It's not easy to find low marketcap gems in the gaming sector. As an investor, I need all the tools at my disposal to filter out the trash.” ",
        avt: feedback2,
        name: 'Ahmed',
        position: 'Investor in Games',
    },
    {
        id: 3,
        text: " “You can earn the most with games in the early stage. You can usually find these early stage games on websites such as PlaytoEarn.” ",
        avt: feedback3,
        name: 'Cryptobull',
        position: 'I love Crypto',
    },
   
]

export default dataTestimonial;