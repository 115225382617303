

import img1 from '../images/icon/Cloud.png';
import img2 from '../images/icon/Wallet.png';
import img3 from '../images/icon/Mining.png';
import img4 from '../images/icon/Comparison.png';

const dataWork = [
    {
        id: 1,
        img: img1,
        step: 'Step 1',
        title: 'Search',
        text: 'Search across thousands of Web3 games. Filter for marketcap and earning potential!'
    },
    {
        id: 2,
        img: img2,
        step: 'Step 2',
        title: 'Invest',
        text: 'Optionally, buy gaming tokens or NFTs to maximize your earning revenue!'
    },
    {
        id: 3,
        img: img3,
        step: 'Step 3',
        title: 'Play',
        text: 'Earn crypto tokens and NFTs while playing that have real world market value!'
    },
    {
        id: 4,
        img: img4,
        step: 'Step 4',
        title: 'Sell',
        text: 'Sell your accumulated gaming assets on the open market for a hefty profit!'
    },
   
]

export default dataWork;