import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

import dataBlog from "../assets/fake-data/data-blog";

import { Link, useNavigate, useParams } from "react-router-dom";
import Sale01 from "../components/sale/Sale01";
import PageTitle from "../components/pagetitle/blog_title";

import img1 from "../assets/images/blog/blog-01.jpg";
import img2 from "../assets/images/blog/blog-details-1.jpg";
import img3 from "../assets/images/blog/blog-details-2.jpg";
import img4 from "../assets/images/blog/blog-details-3.jpg";
import axios from "axios";
import Markdown from "react-markdown";
import gfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { marked } from "marked";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { LoginContext } from "../components/LoginContext";
import Profile from "../assets/images/icon/profile.png";
import { useLayoutEffect } from "react";

BlogDetails.propTypes = {};

function BlogDetails(props) {
  const [isOpen, setOpen] = useState(false);
  const [Data, setData] = useState(null);
  const [FullDate, setFullDate] = useState("");
  const [RecentPosts, setRecentPosts] = useState([]);
  const [Categories, setCategories] = useState([]);
  const [Comments, setComments] = useState([]);
  const [CommentName, setCommentName] = useState(null);
  const [CommentEmail, setCommentEmail] = useState(null);
  const [CommentMessage, setCommentMessage] = useState(null);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [theme, settheme] = useState("light");
  const [ShowPage, setShowPage] = useState(false);

  const { id } = useParams();

  const navigate = useNavigate();
  const { isLoggedin, User } = useContext(LoginContext);

  const fetchPost = () => {
    axios(
      `${process.env.REACT_APP_SERVER_URL}/api/blogs?filters[slug][$eq]=${id}&populate=*`
    ).then((res) => {
      if (res.status == 200) {
        console.log(res.data.data.length);
        if (res.data.data.length > 0) {
          if (window.location.href[window.location.href.length - 1] == "/")
            window.location.href = window.location.href.slice(0, -1);
          setShowPage(true);
          document
            .querySelector('meta[name="description"]')
            .setAttribute(
              "content",
              res.data.data[0].attributes.seo_description
            );
          document.title = res.data.data[0].attributes.seo_title;

          setData(res.data.data[0]);

          let date = new Date(res.data.data[0].attributes.createdAt);
          let month = date.toLocaleString("default", {
            month: "short",
          });
          let day = date.getDate();
          let year = date.getFullYear();

          let full_date = `${month} ${day}, ${year}`;
          console.log(full_date);
          setFullDate(full_date);
        } else {
          navigate("/404");
        }
      }
    });
  };

  function fetchComments() {
    axios(
      `${process.env.REACT_APP_SERVER_URL}/api/comments?filters\[blog\][slug][$eq]=${id}&filters[is_verified][$eq]=true&populate=*`
    ).then((res) => {
      if (res.status == 200) {
        setComments(res.data.data);
      }
    });
  }

  useLayoutEffect(() => {
    if (!id) {
      return;
    }

    axios(
      `${process.env.REACT_APP_SERVER_URL}/api/blogs?filters[slug][$ne]=${id}&sort=id:desc&pagination[limit]=3&populate=*`
    ).then((res) => {
      if (res.status == 200) {
        setRecentPosts(res.data.data);

        if (localStorage.getItem("theme")) {
          if (localStorage.getItem("theme") == "is_dark") {
            settheme("dark");
          } else {
            settheme("light");
          }
        }
      }
    });

    axios(process.env.REACT_APP_SERVER_URL + "/api/categories").then((res) => {
      if (res.status == 200) {
        setCategories(res.data.data);
      }
    });

    fetchPost();
    fetchComments();
  }, [id]);

  const PostComment = () => {
    if (
      // CommentName?.length > 0 &&
      // CommentEmail?.length > 0 &&
      CommentMessage?.length > 0
    ) {
      if (1 > 0) {
        const body = {
          data: {
            user: User.id,
            message: CommentMessage,
            blog: Data?.id,
          },
        };

        axios
          .post(process.env.REACT_APP_SERVER_URL + "/api/comments", body)
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              setErrorMessage("");
              setCommentName(null);
              setCommentEmail(null);
              setCommentMessage(null);

              document.getElementById("comment_message").value = "";
              fetchComments();
              document.getElementById("comments").scrollIntoView();
            }
          });
      } else {
        setErrorMessage("Email format is invalid");
      }
    }
  };

  return (
    <>
      {ShowPage ? (
        <div style={{ flex: "1" }}>
          <PageTitle
            heading="Blog Details"
            title="Blog"
            blog={Data?.attributes.title}
          />

          <section className="blog-details">
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-md-12">
                  <div className="blog-main">
                    <h3 className="title" style={{ wordWrap: "break-word" }}>
                      {Data?.attributes.title}
                    </h3>

                    <div
                      className="meta"
                      style={{ display: "flex", alignItems: "flex-start" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "8px",
                        }}
                      >
                        {Data?.attributes?.categories.data.map((ele) => {
                          return (
                            <Link
                              className="category btn-action"
                              to={
                                "/category/" +
                                ele.attributes.title
                                  .toLowerCase()
                                  .replaceAll(" ", "-")
                              }
                            >
                              {ele.attributes.title}
                            </Link>
                          );
                        })}
                      </div>

                      <div className="meta-info">
                        <Link
                          to="#"
                          className="time"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {FullDate}
                        </Link>
                      </div>
                    </div>
                    <div
                      className="content blog_content"
                      data-color-mode={theme}
                    >
                      <MarkdownPreview source={Data?.attributes.description} />
                    </div>

                    <div className="details-bottom">
                      <div className="share">
                        <h6>Share:</h6>
                        <ul style={{ zIndex: "100" }}>
                          <li>
                            <a
                              target="_blank"
                              href={
                                "http://www.facebook.com/share.php?u=" +
                                window.location.href
                              }
                            >
                              <i className="fa-brands fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href={`https://www.reddit.com/submit?url=${window.location.href}&title=check this website`}
                            >
                              <i className="fa-brands fa-reddit"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href={`https://www.linkedin.com/shareArticle?url=${window.location.href}&title=Check this website`}
                            >
                              <i className="fa-brands fa-linkedin"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href={
                                "https://twitter.com/intent/tweet?text=" +
                                window.location.href
                              }
                            >
                              <i className="fa-brands fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href={`https://pinterest.com/pin/create/bookmarklet/?media=${
                                process.env.REACT_APP_SERVER_URL +
                                Data?.attributes.thumbnail.data.attributes.url
                              }&url=${window.location.href}&description=${
                                Data?.attributes.title
                              }`}
                            >
                              <i className="fa-brands fa-pinterest"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href={`https://api.whatsapp.com/send?text=${Data?.attributes.title} ${window.location.href}`}
                            >
                              <i className="fa-brands fa-whatsapp"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href={`mailto:?subject=${Data?.attributes.title}&body=${window.location.href}`}
                            >
                              <i class="fa-solid fa-envelope"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="comment_form">
                      <h6 className="heading">Leave a comment</h6>

                      <div className="form-group">
                        <textarea
                          id="comment_message"
                          className="form-control"
                          cols="30"
                          rows="10"
                          style={{
                            background: "transparent",
                            color: theme == "light" ? "black" : "white",
                            border:
                              CommentMessage == null ||
                              CommentMessage?.length > 0
                                ? "1px solid #ced4da"
                                : "1px solid red",
                          }}
                          placeholder="Message"
                          value={CommentMessage}
                          onChange={(e) => {
                            setCommentMessage(e.target.value);
                          }}
                        ></textarea>
                      </div>

                      <div
                        style={{
                          fontWeight: "bold",
                          color: "red",
                          fontSize: "17px",
                          marginTop: "20px",
                        }}
                      >
                        {ErrorMessage}
                      </div>

                      {isLoggedin ? (
                        <button className="btn-action" onClick={PostComment}>
                          Send comment
                        </button>
                      ) : (
                        <div style={{ color: "#3772ff", fontWeight: "bold" }}>
                          Login to post comment
                        </div>
                      )}
                    </div>

                    <div className="comments" id="comments">
                      <h6>
                        Comments ({Comments?.length ? Comments?.length : "0"})
                      </h6>

                      {Data?.attributes?.comments?.data?.length == 0 && (
                        <div>No Comments.</div>
                      )}

                      {Comments?.map((ele) => (
                        <div className="comments_content">
                          <div className="img">
                            <img
                              src={
                                ele.attributes.user.data.attributes.img
                                  ? process.env.REACT_APP_PROXY_URL +
                                    ele.attributes.user.data.attributes.img
                                  : Profile
                              }
                              alt="profile image"
                            />
                          </div>

                          <div>
                            <div
                              style={{
                                color: theme == "light" ? "black" : "white",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              @{ele.attributes.user.data.attributes.username}
                            </div>
                            <div style={{ fontSize: "14px" }}>
                              {ele.attributes.message}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-12">
                  <div className="sidebar">
                    <div className="widget recent mt-0">
                      <h6 className="heading">Recent Post</h6>

                      <ul>
                        {RecentPosts.map((ele) => (
                          <li key={ele.id}>
                            <Link to={"/blog/" + ele.attributes.slug}>
                              <div className="image">
                                <img
                                  src={
                                    process.env.REACT_APP_SERVER_URL +
                                    ele.attributes.thumbnail.data.attributes.url
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="content">
                                <div
                                  to="#"
                                  className="category"
                                  style={{ wordWrap: "break-word" }}
                                >
                                  {
                                    ele?.attributes?.categories?.data[0]
                                      ?.attributes?.title
                                  }
                                </div>
                                <div
                                  to=""
                                  className="title"
                                  style={{ wordWrap: "break-word" }}
                                >
                                  {ele.attributes.title.length > 50
                                    ? ele.attributes.title.slice(0, 50) + "..."
                                    : ele.attributes.title}
                                </div>
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="widget tags">
                      <h6 className="heading">Popular Categories</h6>

                      <ul className="ul">
                        {Categories.map((ele) => (
                          <li>
                            <Link
                              to={
                                "/category/" +
                                ele.attributes.title
                                  .toLowerCase()
                                  .replaceAll(" ", "-")
                              }
                            >
                              {" "}
                              {ele.attributes.title.length > 20
                                ? ele.attributes.title.slice(0, 20) + "..."
                                : ele.attributes.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default BlogDetails;
