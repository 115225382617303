import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";
import CryptoMarket from "../components/crypto/CryptoMarket";
import Blog from "../components/blog/Blog";
import PageHead from "../components/pagetitle/PageHead";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import img from "../assets/images/layout/banner-04.png";
import dataBlog from "../assets/fake-data/data-blog";

import dataCoin2 from "../assets/fake-data/data-coin-2";
import axios from "axios";
import ReactPaginate from "react-paginate";
import CoinsTable from "../components/CoinsTable";
import { LoginContext } from "../components/LoginContext";
import SelectField from "../components/Select/Select";
import SelectFilter from "../components/SelectFilter/SelectFilter";

Markets.propTypes = {};

function Markets(props) {
  const [dataBlock] = useState({
    heading: "Today’s Cryptocurrency prices",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  });

  const { isLoggedin, User } = useContext(LoginContext);

  const [Favs, setFavs] = useState([]);

  useEffect(() => {
    if (isLoggedin) {
      axios(
        process.env.REACT_APP_SERVER_URL +
          "/api/favorites?populate=*&filters[user_id][$eq]=" +
          User?.id
      ).then((res) => {
        if (res.status == 200) {
          setFavs(res.data.data);
        }
      });
    }
  }, [User]);

  function fetchFavs(id) {
    if (isLoggedin) {
      axios(
        process.env.REACT_APP_SERVER_URL +
          "/api/favorites?populate=*&filters[user_id][$eq]=" +
          User?.id
      ).then((res) => {
        if (res.status == 200) {
          setFavs(res.data.data);
          setActiveFilter(id);
          HandleFilterChange(
            ActiveRating,
            BlockChainFilter,
            id,
            GenreFilter,
            PlatformFilter
          );
        }
      });
    }
  }

  const [dataCoinTab] = useState([
    {
      id: 1,
      title: "Hot",
    },
    {
      id: 2,
      title: "New",
    },
    {
      id: 3,
      title: "DeFi",
    },
    {
      id: 4,
      title: "Spot",
    },
  ]);

  const [Tables, setTables] = useState([]);
  const [TablesData, setTablesData] = useState([]);
  const [Columns, setColumns] = useState([]);
  const [Coins, setCoins] = useState([]);
  const [CryptoCoins, setCryptoCoins] = useState([]);
  const [AllCoins, setAllCoins] = useState([]);
  const [TableCoins, setTableCoins] = useState([]);
  const [CurrentTable, setCurrentTable] = useState("");
  const [is_trade, setis_trade] = useState(null);
  const [ColumnsConversions, setColumnsConversions] = useState({});
  const [BlockChains, setBlockChains] = useState({});
  const [BlockChainsData, setBlockChainsData] = useState([]);
  const [Platforms, setPlatforms] = useState({});

  const [Loop, setLoop] = useState();
  const [FetchLoop, setFetchLoop] = useState();
  const [TableSlug, setTableSlug] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [RecentPosts, setRecentPosts] = useState([]);
  const [ActiveRating, setActiveRating] = useState("0");

  const [ActiveFilter, setActiveFilter] = useState("0");
  const [tableloading, settableloading] = useState(true);

  const [ListFilters, setListFilters] = useState([]);
  const [PageCount, setPageCount] = useState(1);
  const [ItemOffset, setItemOffset] = useState(0);
  const [ItemsPerPage, setItemsPerPage] = useState(50);
  const [EndOffset, setEndOffset] = useState(ItemOffset + ItemsPerPage);

  const [BlogHeading, setBlogHeading] = useState("");
  const [BlogContent, setBlogContent] = useState("");
  const [theme, settheme] = useState(false);
  const [TopPageTitle, setTopPageTitle] = useState("");

  const { table } = useParams();
  const navigate = useNavigate();

  const [SumMarketCap, setSumMarketCap] = useState("");
  const [BlockChainsSelect, setBlockChainsSelect] = useState([]);
  const [GenreSelect, setGenreSelect] = useState([]);
  const [PlatformSelect, setPlatformSelect] = useState([]);

  const [BlockChainFilter, setBlockChainFilter] = useState("*");
  const [GenreFilter, setGenreFilter] = useState("*");
  const [PlatformFilter, setPlatformFilter] = useState("*");

  const [CurrentPage, setCurrentPage] = useState("0");

  const [isEarn, setisEarn] = useState(false);

  useEffect(() => {
    FetchGainersCoins();
  }, []);

  const FetchGainersCoins = () => {
    axios(
      process.env.REACT_APP_SERVER_URL +
        `/api/coins?fields[0]=name&fields[1]=coin_id&fields[2]=market_cap`
    ).then(async (res) => {
      let temp_coins = res.data.data.filter(
        (e) => e.attributes.coin_id != null
      );
      let coins_comma = [];

      temp_coins.map((ele) => {
        if (ele.attributes.coin_id) {
          coins_comma.push(ele.attributes.coin_id);
        }
      });
      axios(
        process.env.REACT_APP_PROXY_URL + "/api/" + coins_comma.join(",")
      ).then(({ data }) => {
        let coins = [];
        let all_coins = [];

        temp_coins.forEach((ele) => {
          const coin_api = data.data[ele.attributes.coin_id];

          if (ele.attributes["market_cap"] == null) {
            ele.attributes["market_cap"] =
              coin_api["quote"]["USD"]["market_cap"];
          }

          if (coin_api["quote"]["USD"]["market_cap"]) {
            all_coins.push(ele);
          }
        });

        let sum_market_cap = 0;
        let sum_volumes = 0;

        all_coins.forEach((ele) => {
          console.log("coins", ele);
          sum_market_cap += parseFloat(ele.attributes.market_cap);
        });

        setSumMarketCap(
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          })
            .format(Math.round(sum_market_cap))
            .toString()
            .split(".")[0]
        );
      });
    });
  };

  let active_filter = null;

  function sort(arr, type) {
    if (type == false) {
      return arr.sort(function (a, b) {
        if (a.attributes.market_cap === b.attributes.market_cap) return 0;
        return b.attributes.market_cap - a.attributes.market_cap;
      });
    } else {
      return arr.sort(function (a, b) {
        if (a.attributes.earnings_rating == b.attributes.earnings_rating)
          return 0;

        console.log(a.attributes.earnings_rating, b.attributes.earnings_rating);
        return b.attributes.earnings_rating - a.attributes.earnings_rating;
      });
    }
  }

  function sort2(arr, type) {
    if (type == true) {
      return arr.sort(function (a, b) {
        if (a.attributes.market_cap === b.attributes.market_cap) return 0;
        return b.attributes.market_cap - a.attributes.market_cap;
      });
    } else {
      return arr.sort(function (a, b) {
        if (a.attributes.earnings_rating == b.attributes.earnings_rating)
          return 0;

        console.log(a.attributes.earnings_rating, b.attributes.earnings_rating);
        return b.attributes.earnings_rating - a.attributes.earnings_rating;
      });
    }
  }

  function fetchCoinsData() {
    axios(
      process.env.REACT_APP_SERVER_URL +
        `/api/tables?filters[slug][$eq]=${table}&populate=columns`
    ).then(async (res) => {
      if (res.status == 200) {
        let tables = [];

        if (res.data.data.length > 0) {
          setTableSlug(res.data.data[0].attributes.slug);
          setColumns(res.data.data[0].attributes.columns.data);
          setis_trade(res.data.data[0].attributes.is_trade);
          setBlogHeading(res.data.data[0].attributes.blog_heading);
          setBlogContent(res.data.data[0].attributes.blog_description);
          setTopPageTitle(res.data.data[0].attributes.page_title);
          document
            .querySelector('meta[name="description"]')
            .setAttribute(
              "content",
              res.data.data[0].attributes.seo_description
            );
          document.title = res.data.data[0].attributes.seo_title;

          if (localStorage.getItem("theme")) {
            if (localStorage.getItem("theme") == "is_dark") {
              settheme("dark");
            } else {
              settheme("light");
            }
          }

          axios(
            process.env.REACT_APP_PROXY_URL + `/getCoinTableSlug/${table}`
          ).then(({ data }) => {
            const temp_coins = data.data;

            let coins_comma = [];

            temp_coins.map((ele) => {
              if (ele.attributes.coin_id) {
                coins_comma.push(ele.attributes.coin_id);
              }
            });

            setTableCoins(temp_coins);

            axios(
              coins_comma.length > 0
                ? process.env.REACT_APP_PROXY_URL +
                    "/api/" +
                    coins_comma.join(",")
                : process.env.REACT_APP_PROXY_URL + "/api/5"
            ).then(({ data }) => {
              let coins = [];

              temp_coins.forEach((ele) => {
                const coin_api = data.data[ele.attributes.coin_id];

                if (coin_api) {
                  if (ele.attributes["d_7"] == null) {
                    ele.attributes["d_7"] =
                      coin_api["quote"]["USD"]["percent_change_7d"];
                  }
                  if (ele.attributes["h_24"] == null) {
                    ele.attributes["h_24"] =
                      coin_api["quote"]["USD"]["percent_change_24h"];
                  }
                  if (ele.attributes["market_cap"] == null) {
                    ele.attributes["market_cap"] =
                      coin_api["quote"]["USD"]["market_cap"];
                  }
                  if (ele.attributes["name"] == null) {
                    ele.attributes["name"] = coin_api["name"];
                  }
                  if (ele.attributes["price"] == null) {
                    ele.attributes["price"] = coin_api["quote"]["USD"]["price"];
                  }
                  if (ele.attributes["supply"] == null) {
                    ele.attributes["supply"] = coin_api["circulating_supply"];
                  }
                  if (ele.attributes["volume_24"] == null) {
                    ele.attributes["volume_24"] =
                      coin_api["quote"]["USD"]["volume_24h"];
                  }
                }
                coins.push(ele);
              });

              axios(
                process.env.REACT_APP_SERVER_URL + "/api/columns-conversions"
              ).then((conversions_res) => {
                if (conversions_res.status == 200) {
                  if (conversions_res.data.data.length > 0) {
                    let columns_conversions = {};

                    conversions_res.data.data.forEach((ele) => {
                      columns_conversions[ele.attributes.label] =
                        ele.attributes.value;
                    });

                    setColumnsConversions(columns_conversions);
                    axios(
                      process.env.REACT_APP_SERVER_URL +
                        `/api/list-filters?populate=*`
                    ).then((filters) => {
                      if (filters.status == 200) {
                        if (filters.data.data.length > 0) {
                          setListFilters(filters.data.data);
                          // setActiveFilter(filters.data.data[0].id.toString());
                          active_filter = filters.data.data[0].id.toString();

                          let cols_w_trust =
                            res.data.data[0].attributes.columns.data.filter(
                              (e) => e.attributes.name == "Trust Rating"
                            ).length > 0
                              ? true
                              : false;

                          let cols_w_chain =
                            res.data.data[0].attributes.columns.data.filter(
                              (e) => e.attributes.name == "BlockCahin"
                            ).length > 0
                              ? true
                              : false;

                          coins.sort(function (a, b) {
                            return a.id - b.id;
                          });

                          setAllCoins(coins);

                          // setCryptoCoins(coins.slice(0, 4));

                          setisLoading(false);
                          settableloading(false);
                          // setPageCount(
                          //   sort2(filtered_list, cols_w_trust).length /
                          //     ItemsPerPage
                          // );
                        }
                      }
                    });
                  }
                }
              });
            });
          });
        } else {
          navigate("/404");
        }
      }
    });
  }

  useEffect(() => {
    if (!table) return;
    if (window.location.href[window.location.href.length - 1] == "/")
      window.location.href = window.location.href.slice(0, -1);
    axios(
      process.env.REACT_APP_SERVER_URL +
        `/api/tables?filters[slug][$eq]=${table}&populate[0]=columns&populate[1]=top_coins`
    ).then(async (res) => {
      if (res.status == 200) {
        let tables = [];

        if (res.data.data.length > 0) {
          setTableSlug(res.data.data[0].attributes.slug);
          setColumns(res.data.data[0].attributes.columns.data);
          setis_trade(res.data.data[0].attributes.is_trade);
          setBlogHeading(res.data.data[0].attributes.blog_heading);
          setBlogContent(res.data.data[0].attributes.blog_description);
          setTopPageTitle(res.data.data[0].attributes.page_title);
          setisEarn(res.data.data[0].attributes.is_earn);
          document
            .querySelector('meta[name="description"]')
            .setAttribute(
              "content",
              res.data.data[0].attributes.seo_description
            );
          document.title = res.data.data[0].attributes.seo_title;

          if (localStorage.getItem("theme")) {
            if (localStorage.getItem("theme") == "is_dark") {
              settheme("dark");
            } else {
              settheme("light");
            }
          }

          axios(
            process.env.REACT_APP_PROXY_URL + `/getCoinTableSlug/${table}`
          ).then(({ data }) => {
            const temp_coins = data.data;

            let coins_comma = [];

            temp_coins.map((ele) => {
              if (ele.attributes.coin_id) {
                coins_comma.push(ele.attributes.coin_id);
              }
            });

            setTableCoins(temp_coins);

            axios(
              coins_comma.length > 0
                ? process.env.REACT_APP_PROXY_URL +
                    "/api/" +
                    coins_comma.join(",")
                : process.env.REACT_APP_PROXY_URL + "/api/5"
            ).then(({ data }) => {
              let coins = [];

              temp_coins.forEach((ele) => {
                const coin_api = data.data[ele.attributes.coin_id];

                if (coin_api) {
                  if (ele.attributes["d_7"] == null) {
                    ele.attributes["d_7"] =
                      coin_api["quote"]["USD"]["percent_change_7d"];
                  }
                  if (ele.attributes["h_24"] == null) {
                    ele.attributes["h_24"] =
                      coin_api["quote"]["USD"]["percent_change_24h"];
                  }
                  if (ele.attributes["market_cap"] == null) {
                    ele.attributes["market_cap"] =
                      coin_api["quote"]["USD"]["market_cap"];
                  }
                  if (ele.attributes["name"] == null) {
                    ele.attributes["name"] = coin_api["name"];
                  }
                  if (ele.attributes["price"] == null) {
                    ele.attributes["price"] = coin_api["quote"]["USD"]["price"];
                  }
                  if (ele.attributes["supply"] == null) {
                    ele.attributes["supply"] = coin_api["circulating_supply"];
                  }
                  if (ele.attributes["volume_24"] == null) {
                    ele.attributes["volume_24"] =
                      coin_api["quote"]["USD"]["volume_24h"];
                  }
                }
                coins.push(ele);
              });

              axios(
                process.env.REACT_APP_SERVER_URL + "/api/columns-conversions"
              ).then((conversions_res) => {
                if (conversions_res.status == 200) {
                  if (conversions_res.data.data.length > 0) {
                    let columns_conversions = {};

                    conversions_res.data.data.forEach((ele) => {
                      columns_conversions[ele.attributes.label] =
                        ele.attributes.value;
                    });

                    setColumnsConversions(columns_conversions);
                    axios(
                      process.env.REACT_APP_SERVER_URL +
                        `/api/list-filters?populate=*`
                    ).then((filters) => {
                      if (filters.status == 200) {
                        if (filters.data.data.length > 0) {
                          setListFilters(filters.data.data);
                          // setActiveFilter(filters.data.data[0].id.toString());
                          active_filter = filters.data.data[0].id.toString();

                          let cols_w_trust =
                            res.data.data[0].attributes.columns.data.filter(
                              (e) => e.attributes.name == "Trust Rating"
                            ).length > 0
                              ? true
                              : false;

                          let cols_w_chain =
                            res.data.data[0].attributes.columns.data.filter(
                              (e) => e.attributes.name == "BlockCahin"
                            ).length > 0
                              ? true
                              : false;
                          let cols_w_platform =
                            res.data.data[0].attributes.columns.data.filter(
                              (e) => e.attributes.name == "Platform"
                            ).length > 0
                              ? true
                              : false;
                          let cols_w_genre =
                            res.data.data[0].attributes.columns.data.filter(
                              (e) => e.attributes.name == "Genre"
                            ).length > 0
                              ? true
                              : false;

                          coins.sort(function (a, b) {
                            return a.id - b.id;
                          });
                          setAllCoins(coins);

                          let top_coins = [];

                          res.data?.data[0]?.attributes?.top_coins?.data?.forEach(
                            (top_coin) => {
                              if (
                                coins.filter(
                                  (c) =>
                                    c.id.toString() == top_coin.id.toString()
                                ).length > 0
                              ) {
                                top_coins.push(
                                  coins.filter(
                                    (c) =>
                                      c.id.toString() == top_coin.id.toString()
                                  )[0]
                                );
                              }
                            }
                          );

                          console.log("top_coins", top_coins);

                          setCryptoCoins(top_coins);
                          let filtered_rating;
                          if (ActiveRating == "0") {
                            filtered_rating = coins;
                          }
                          if (ActiveRating == "1") {
                            console.log("filters", cols_w_trust);
                            if (cols_w_trust) {
                              filtered_rating = coins.filter(
                                (e) => e.attributes.trust_rating >= 7
                              );
                            } else {
                              filtered_rating = coins.filter(
                                (e) => e.attributes.earnings_rating >= 7
                              );
                            }
                          }

                          if (ActiveRating == "2") {
                            if (cols_w_trust) {
                              filtered_rating = coins.filter(
                                (e) =>
                                  e.attributes.trust_rating >= 3 &&
                                  e.attributes.trust_rating < 7
                              );
                            } else {
                              filtered_rating = coins.filter(
                                (e) =>
                                  e.attributes.earnings_rating >= 3 &&
                                  e.attributes.earnings_rating < 7
                              );
                            }
                          }

                          if (ActiveRating == "3") {
                            if (cols_w_trust) {
                              filtered_rating = coins.filter(
                                (e) =>
                                  e.attributes.trust_rating >= 0 &&
                                  e.attributes.trust_rating < 3
                              );
                            } else {
                              filtered_rating = coins.filter(
                                (e) =>
                                  e.attributes.earnings_rating >= 0 &&
                                  e.attributes.earnings_rating < 3
                              );
                            }
                          }

                          let filtered_chains = [];

                          if (cols_w_chain) {
                            if (BlockChainFilter == "*") {
                              filtered_chains = filtered_rating;
                            } else {
                              filtered_chains = filtered_rating.filter(
                                (ele) => {
                                  return ele.attributes.blockchains.data.some(
                                    (chain) =>
                                      chain.id.toString() == BlockChainFilter
                                  );
                                }
                              );
                            }
                          } else {
                            filtered_chains = filtered_rating;
                          }

                          let filtered_plat = [];

                          if (cols_w_platform) {
                            if (PlatformFilter == "*") {
                              filtered_plat = filtered_chains;
                            } else {
                              filtered_plat = filtered_chains.filter((ele) => {
                                return ele.attributes.platforms.data.some(
                                  (chain) =>
                                    chain.id.toString() == PlatformFilter
                                );
                              });
                            }
                          } else {
                            filtered_plat = filtered_chains;
                          }

                          let filtered_list = filtered_plat;
                          setisLoading(false);
                          settableloading(false);
                          setPageCount(
                            sort2(filtered_list, cols_w_trust).length /
                              ItemsPerPage
                          );
                          setCoins(sort2(filtered_list, cols_w_trust));
                        }
                      }
                    });
                  }
                }
              });
            });
          });
        } else {
          navigate("/404");
        }
      }
      axios(
        process.env.REACT_APP_SERVER_URL + `/api/blockchains?populate=*`
      ).then((res) => {
        if (res.status == 200) {
          if (res.data.data.length > 0) {
            let chains = {};

            let chains_select = [];

            chains_select.push({
              value: "*",
              label: "Show All",
            });

            res.data.data.forEach((ele) => {
              chains[ele.attributes.title] =
                ele.attributes.icon.data.attributes.url;

              chains_select.push({
                value: ele.attributes.title,
                label: ele.attributes.title,
              });
            });

            setBlockChainsSelect(chains_select);

            setBlockChains(chains);
            setBlockChainsData(res.data.data);
          }
        }
      });
      axios(
        process.env.REACT_APP_SERVER_URL + `/api/platforms?populate=*`
      ).then((res) => {
        if (res.status == 200) {
          if (res.data.data.length > 0) {
            let data = {};

            let platform_select = [];

            platform_select.push({
              value: "*",
              label: "Show All",
            });

            res.data.data.forEach((ele) => {
              data[ele?.attributes?.title] =
                ele.attributes.icon.data[0].attributes.url;

              platform_select.push({
                value: ele.attributes.title,
                label: ele.attributes.title,
              });
            });

            setPlatformSelect(platform_select);
            setPlatforms(data);
          }
        }
      });
      axios(process.env.REACT_APP_SERVER_URL + `/api/genres?populate=*`).then(
        (res) => {
          if (res.status == 200) {
            if (res.data.data.length > 0) {
              let genres_select = [];

              genres_select.push({
                value: "*",
                label: "Show All",
              });

              res.data.data.forEach((ele) => {
                genres_select.push({
                  value: ele.attributes.title,
                  label: ele.attributes.title,
                });
              });

              setGenreSelect(genres_select);
            }
          }
        }
      );

      axios(
        `${process.env.REACT_APP_SERVER_URL}/api/blogs?sort=id:desc&pagination[limit]=3&populate=*`
      ).then((res) => {
        if (res.status == 200) {
          setRecentPosts(res.data.data);
        }
      });
    });
  }, []);

  let myinterval;

  useEffect(() => {
    setLoop();
    setInterval(() => {
      fetchCoinsData();
    }, 300000);
  }, [CurrentTable]);

  const FetchCoins = (
    table_id,
    active_rating = ActiveRating,
    active_chain = BlockChainFilter,
    active_filter,
    genre_filter = GenreFilter,
    platform_filter = PlatformFilter
  ) => {
    if (table_id) {
      let cols_w_trust =
        Columns.filter((e) => e.attributes.name == "Trust Rating").length > 0
          ? true
          : false;

      AllCoins.sort(function (a, b) {
        return a.id - b.id;
      });
      console.log("AllCoins", AllCoins);
      let filtered_rating;
      if (active_rating == "0") {
        filtered_rating = AllCoins;
      }
      if (active_rating == "1") {
        console.log("filters", cols_w_trust);
        if (cols_w_trust) {
          filtered_rating = AllCoins.filter(
            (e) => e.attributes.trust_rating >= 7
          );
        } else {
          filtered_rating = AllCoins.filter(
            (e) => e.attributes.earnings_rating >= 7
          );
        }
      }

      if (active_rating == "2") {
        if (cols_w_trust) {
          filtered_rating = AllCoins.filter(
            (e) =>
              e.attributes.trust_rating >= 3 && e.attributes.trust_rating < 7
          );
        } else {
          filtered_rating = AllCoins.filter(
            (e) =>
              e.attributes.earnings_rating >= 3 &&
              e.attributes.earnings_rating < 7
          );
        }
      }

      if (active_rating == "3") {
        if (cols_w_trust) {
          filtered_rating = AllCoins.filter(
            (e) =>
              e.attributes.trust_rating >= 0 && e.attributes.trust_rating < 3
          );
        } else {
          filtered_rating = AllCoins.filter(
            (e) =>
              e.attributes.earnings_rating >= 0 &&
              e.attributes.earnings_rating < 3
          );
        }
      }

      let filtered_platform = [];

      if (platform_filter == "*") {
        filtered_platform = filtered_rating;
      } else {
        filtered_platform = filtered_rating.filter((ele) => {
          return ele.attributes.platforms.data.some(
            (chain) =>
              chain.attributes.title.toString().toLowerCase() ==
              platform_filter.toLowerCase()
          );
        });
      }

      let filtered_genre = [];

      if (genre_filter == "*") {
        filtered_genre = filtered_platform;
      } else {
        filtered_genre = filtered_platform.filter((ele) => {
          return ele.attributes.genres.data.some(
            (chain) =>
              chain.attributes.title.toString().toLowerCase() ==
              genre_filter.toLowerCase()
          );
        });
      }

      let filtered_chains = [];

      if (active_chain == "*") {
        filtered_chains = filtered_genre;
      } else {
        filtered_chains = filtered_genre.filter((ele) => {
          return ele.attributes.blockchains.data.some(
            (chain) =>
              chain.attributes.title.toString().toLowerCase() ==
              active_chain.toLowerCase()
          );
        });
      }
      console.log("filtered_genre", filtered_chains);
      let filtered_list = [];
      if (active_filter == 8) {
        if (isLoggedin) {
          axios(
            process.env.REACT_APP_SERVER_URL +
              "/api/favorites?populate=*&filters[user_id][$eq]=" +
              User?.id
          ).then((res) => {
            if (res.status == 200) {
              setFavs(res.data.data);
              filtered_list = filtered_chains.filter((ele) => {
                return res.data.data.some(
                  (coin) => ele.id.toString() == coin.attributes.coin_id
                );
              });
              console.log("active_filter", Favs);
              settableloading(false);

              setCoins(sort2(filtered_list, cols_w_trust));
            }
          });
        } else {
          filtered_list = [];
        }
      } else if (active_filter.toString() == "0") {
        filtered_list = filtered_chains;
      } else if (active_filter == 10) {
        console.log("filtered_chainslol", filtered_chains[0]);
        filtered_list = filtered_chains.sort(function (a, b) {
          return b.id - a.id;
        });
      } else if (active_filter == 9) {
        console.log("filtered_chains2", filtered_chains);
        filtered_list = filtered_chains.filter((ele) => {
          return ele.attributes.list_filters.data.some(
            (e) => e.id.toString() == active_filter
          );
        });
      }
      // console.log("filtered_chainslol2", filtered_list);
      console.log("active_filter", Favs);
      settableloading(false);
      if (active_filter == 10) {
        setCoins(filtered_list);
      } else {
        setCoins(sort2(filtered_list, cols_w_trust));
      }
      // setItemOffset(0);

      console.log("divied", filtered_list.length / ItemsPerPage);
      setPageCount(filtered_list.length / ItemsPerPage);
    }
  };

  const HandleFilterChange = (
    active_rating,
    active_chain,
    active_filter,
    genre_filter,
    platform_filter
  ) => {
    console.log("col", Columns);
    setCoins([]);
    settableloading(true);
    FetchCoins(
      table,
      active_rating,
      active_chain,
      active_filter,
      genre_filter,
      platform_filter
    );
  };

  //   const HandleTableChange = (table) => {
  //     clearInterval(Loop);
  //     clearInterval(FetchLoop);
  //     setisLoading(true);
  //     setCurrentTable(table);
  //     setCoins([]);
  //     setColumns([]);
  //     FetchCoins(table["value"]);
  //     setFetchLoop(
  //       setInterval(() => {
  //         FetchCoins(table["value"]);
  //       }, 1000000)
  //     );
  //   };

  const RatingClassColor = (rating) => {
    if (rating >= 7) {
      return "rating green";
    } else if (rating >= 3) {
      return "rating yellow";
    } else if (rating >= 0) {
      return "rating red";
    }
  };

  const handlePageClick = (e) => {
    const newOffset = (e.selected * ItemsPerPage) % Coins.length;
    console.log("offset", EndOffset);
    setItemOffset(newOffset);
    setEndOffset(newOffset + ItemsPerPage);
  };

  return (
    <div className="markets home-2">
      <section className="banner" style={{ padding: "30px" }}>
        <div className="container">
          <div className="row">
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <h2 className="title title_mobile" style={{ padding: "0px" }}>
                  {TopPageTitle}
                </h2>
                <p className="fs-24 desc desc_mobile">
                  The global gaming crypto market cap is{" "}
                  <span>{SumMarketCap}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CryptoMarket
        Coins={CryptoCoins}
        Platforms={Platforms}
        RatingClassColor={RatingClassColor}
        isLoading={isLoading}
        Columns={Columns}
        BlockChains={BlockChains}
        TableSlug={TableSlug}
        theme={theme}
        is_earn={isEarn}
      />

      <section className="coin-list">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="flat-tabs1">
                {Columns.filter((e) => e.attributes.name == "Trust Rating")
                  .length > 0 ? (
                  <ul className="menu-tab1">
                    <li
                      className={ActiveRating == "0" ? "active" : ""}
                      onClick={() => {
                        setActiveRating("0");
                        HandleFilterChange(
                          "0",
                          BlockChainFilter,
                          ActiveFilter,
                          GenreFilter,
                          PlatformFilter
                        );
                      }}
                    >
                      <h6 className="fs-16">All</h6>
                    </li>
                    <li
                      className={ActiveRating == "1" ? "active" : ""}
                      onClick={() => {
                        setActiveRating("1");
                        HandleFilterChange(
                          "1",
                          BlockChainFilter,
                          ActiveFilter,
                          GenreFilter,
                          PlatformFilter
                        );
                      }}
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <span className="green dot"></span>
                      <h6 className="fs-16">High Trust</h6>
                    </li>
                    <li
                      className={ActiveRating == "2" ? "active" : ""}
                      onClick={() => {
                        setActiveRating("2");
                        HandleFilterChange(
                          "2",
                          BlockChainFilter,
                          ActiveFilter,
                          GenreFilter,
                          PlatformFilter
                        );
                      }}
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <span className="yellow dot"></span>
                      <h6 className="fs-16">Medium Trust</h6>
                    </li>
                    <li
                      className={ActiveRating == "3" ? "active" : ""}
                      onClick={() => {
                        setActiveRating("3");
                        HandleFilterChange(
                          "3",
                          BlockChainFilter,
                          ActiveFilter,
                          GenreFilter,
                          PlatformFilter
                        );
                      }}
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <span className="red dot"></span>
                      <h6 className="fs-16">Low Trust</h6>
                    </li>
                  </ul>
                ) : (
                  <ul className="menu-tab1">
                    <li
                      className={ActiveRating == "0" ? "active" : ""}
                      onClick={() => {
                        setActiveRating("0");
                        HandleFilterChange(
                          "0",
                          BlockChainFilter,
                          ActiveFilter,
                          GenreFilter,
                          PlatformFilter
                        );
                      }}
                    >
                      <h6 className="fs-16">All</h6>
                    </li>
                    <li
                      className={ActiveRating == "1" ? "active" : ""}
                      onClick={() => {
                        setActiveRating("1");
                        HandleFilterChange(
                          "1",
                          BlockChainFilter,
                          ActiveFilter,
                          GenreFilter,
                          PlatformFilter
                        );
                      }}
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <span className="green dot"></span>
                      <h6 className="fs-16">High Earnings</h6>
                    </li>
                    <li
                      className={ActiveRating == "2" ? "active" : ""}
                      onClick={() => {
                        setActiveRating("2");
                        HandleFilterChange(
                          "2",
                          BlockChainFilter,
                          ActiveFilter,
                          GenreFilter,
                          PlatformFilter
                        );
                      }}
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <span className="yellow dot"></span>
                      <h6 className="fs-16">Medium Earnings</h6>
                    </li>
                    <li
                      className={ActiveRating == "3" ? "active" : ""}
                      onClick={() => {
                        setActiveRating("3");
                        HandleFilterChange(
                          "3",
                          BlockChainFilter,
                          ActiveFilter,
                          GenreFilter,
                          PlatformFilter
                        );
                      }}
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <span className="red dot"></span>
                      <h6 className="fs-16">Low Earnings</h6>
                    </li>
                  </ul>
                )}

                <div className="content-tab1">
                  <div className="content-inner">
                    <div className="flat-tabs2">
                      <div className="select_filters">
                        {Columns.filter(
                          (e) => e.attributes.name == "BlockChain"
                        ).length > 0 && (
                          <SelectFilter
                            options={BlockChainsSelect}
                            label={""}
                            placeholder="Select BlockChain..."
                            onchange={(e) => {
                              setBlockChainFilter(e.value);
                              HandleFilterChange(
                                ActiveRating,
                                e.value,
                                ActiveFilter,
                                GenreFilter,
                                PlatformFilter
                              );
                            }}
                          />
                        )}

                        {Columns.filter((e) => e.attributes.name == "Genre")
                          .length > 0 && (
                          <SelectFilter
                            options={GenreSelect}
                            label={""}
                            placeholder="Select Genre..."
                            onchange={(e) => {
                              setGenreFilter(e.value);
                              HandleFilterChange(
                                ActiveRating,
                                BlockChainFilter,
                                ActiveFilter,
                                e.value,
                                PlatformFilter
                              );
                            }}
                          />
                        )}

                        {Columns.filter((e) => e.attributes.name == "Platform")
                          .length > 0 && (
                          <SelectFilter
                            options={PlatformSelect}
                            label={""}
                            placeholder="Select Platform..."
                            onchange={(e) => {
                              setPlatformFilter(e.value);
                              HandleFilterChange(
                                ActiveRating,
                                BlockChainFilter,
                                ActiveFilter,
                                GenreFilter,
                                e.value
                              );
                            }}
                          />
                        )}
                      </div>

                      {/* <ul className="menu-tab2" style={{ flexWrap: "nowrap" }}>
                        <li
                          className={ActiveChain == "*" ? "active" : ""}
                          onClick={() => {
                            setActiveChain("*");
                            HandleFilterChange(ActiveRating, "*", ActiveFilter);
                          }}
                        >
                          <h6 className="fs-16">All</h6>
                        </li>
                        {BlockChainsData.map((ele) => {
                          return (
                            <li
                              style={{ whiteSpace: "nowrap" }}
                              className={
                                ActiveChain == ele.id.toString() ? "active" : ""
                              }
                              onClick={() => {
                                setActiveChain(ele.id.toString());
                                HandleFilterChange(
                                  ActiveRating,
                                  ele.id.toString(),
                                  ActiveFilter
                                );
                              }}
                            >
                              <h6 className="fs-16">{ele.attributes.title}</h6>
                            </li>
                          );
                        })}
                      </ul> */}

                      <div
                        className="menu-tab2"
                        style={{ flexWrap: "nowrap" }}
                      ></div>

                      <div className="content-tab2">
                        <div className="content-inner">
                          <div className="coin-list__main">
                            <div className="flat-tabs">
                              <Tabs>
                                <TabList>
                                  <Tab
                                    onClick={() => {
                                      setActiveFilter("0");
                                      HandleFilterChange(
                                        ActiveRating,
                                        BlockChainFilter,
                                        "0"
                                      );
                                    }}
                                  >
                                    All
                                  </Tab>
                                  {ListFilters.map((idx) => {
                                    console.log("filter", idx);

                                    if (idx.id == 8) {
                                      return (
                                        <Tab
                                          onClick={() => {
                                            console.log("idx", idx.id);
                                            if (idx.id.toString() == "3") {
                                              console.log("idxxxxx");
                                              fetchFavs(idx.id);
                                            } else {
                                              setActiveFilter(idx.id);
                                              HandleFilterChange(
                                                ActiveRating,
                                                BlockChainFilter,
                                                idx.id,
                                                GenreFilter,
                                                PlatformFilter
                                              );
                                            }
                                          }}
                                          key={idx.id}
                                          style={{
                                            display: "flex",
                                            gap: "5px",
                                          }}
                                        >
                                          <span>⭐️</span>
                                          {idx.attributes.name}
                                        </Tab>
                                      );
                                    } else if (idx.id == 10) {
                                      return (
                                        <Tab
                                          onClick={() => {
                                            console.log("idx", idx.id);
                                            if (idx.id.toString() == "3") {
                                              console.log("idxxxxx");
                                              fetchFavs(idx.id);
                                            } else {
                                              setActiveFilter(idx.id);
                                              HandleFilterChange(
                                                ActiveRating,
                                                BlockChainFilter,
                                                idx.id,
                                                GenreFilter,
                                                PlatformFilter
                                              );
                                            }
                                          }}
                                          key={idx.id}
                                          style={{
                                            display: "flex",
                                            gap: "5px",
                                          }}
                                        >
                                          <span>🐤</span>
                                          {idx.attributes.name}
                                        </Tab>
                                      );
                                    } else {
                                      return (
                                        <Tab
                                          onClick={() => {
                                            console.log("idx", idx.id);
                                            if (idx.id.toString() == "3") {
                                              console.log("idxxxxx");
                                              fetchFavs(idx.id);
                                            } else {
                                              setActiveFilter(idx.id);
                                              HandleFilterChange(
                                                ActiveRating,
                                                BlockChainFilter,
                                                idx.id,
                                                GenreFilter,
                                                PlatformFilter
                                              );
                                            }
                                          }}
                                          key={idx.id}
                                          style={{
                                            display: "flex",
                                            gap: "5px",
                                          }}
                                        >
                                          <span>🔥</span>
                                          {idx.attributes.name}
                                        </Tab>
                                      );
                                    }
                                  })}
                                </TabList>

                                {dataCoinTab.map((data) => (
                                  <TabPanel key={data.id}>
                                    <div className="content-inner">
                                      {tableloading ? (
                                        <center>
                                          <span
                                            style={{
                                              border: "5px solid #3772ff",
                                              borderBottomColor: "transparent",
                                            }}
                                            class="loader"
                                          ></span>
                                        </center>
                                      ) : (
                                        <>
                                          <CoinsTable
                                            Columns={Columns}
                                            ColumnsConversions={
                                              ColumnsConversions
                                            }
                                            itemstart={ItemOffset}
                                            itemend={EndOffset}
                                            Coins={Coins}
                                            is_trade={is_trade}
                                            BlockChains={BlockChains}
                                            Platforms={Platforms}
                                            TableSlug={TableSlug}
                                          />

                                          {Coins.length >= 50 && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "20px",
                                              }}
                                            >
                                              <div
                                                style={{ whiteSpace: "nowrap" }}
                                              >
                                                {ItemOffset + 1} - {EndOffset}{" "}
                                                from {Coins.length}
                                              </div>

                                              <div
                                                style={{
                                                  display: "flex",
                                                  whiteSpace: "nowrap",
                                                  width: "100%",
                                                }}
                                              >
                                                <ReactPaginate
                                                  nextLabel="next >"
                                                  onPageChange={handlePageClick}
                                                  pageRangeDisplayed={3}
                                                  marginPagesDisplayed={2}
                                                  pageCount={PageCount}
                                                  // initialPage={CurrentPage}
                                                  previousLabel="< previous"
                                                  pageClassName="page-item"
                                                  pageLinkClassName="page-link"
                                                  previousClassName="page-item"
                                                  previousLinkClassName="page-link"
                                                  nextClassName="page-item"
                                                  nextLinkClassName="page-link"
                                                  breakLabel="..."
                                                  breakClassName="page-item"
                                                  breakLinkClassName="page-link"
                                                  containerClassName="pagination"
                                                  activeClassName="active"
                                                  renderOnZeroPageCount={null}
                                                />
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </TabPanel>
                                ))}
                              </Tabs>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Blog
        BlogData={RecentPosts}
        BlogHeading={BlogHeading}
        BlogContent={BlogContent}
        theme={theme}
      />
    </div>
  );
}

export default Markets;
