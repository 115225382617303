import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from "../button";

import img from "../../assets/images/layout/bannerhome.png";

Banner02.propTypes = {};

function Banner02(props) {
  const [dataBlock] = useState({
    heading: "Find the Best Web3 P2E Blockchain Games",
    desc: "Find the Best Web3 Play-to-Earn Blockchain Games pricing, earnings, and token information!",
  });
  return (
    <section className="banner" style={{ paddingBottom: "55px" }}>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-md-12">
            <div className="banner__content">
              <h2 className="title">{dataBlock.heading}</h2>
              <p className="fs-20 desc">{dataBlock.desc}</p>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="banner__image">
              <img src={img} alt="Rockie" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner02;
