import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import img1 from "../../assets/images/coin/btc.png";
import img2 from "../../assets/images/coin/eth.png";
import img3 from "../../assets/images/coin/tet.png";
import img4 from "../../assets/images/coin/bnb.png";

import "./styles.scss";

import { Link, useParams } from "react-router-dom";
import Loader from "../Loader";
import axios from "axios";

function Crypto04({ Coins, Platforms, isLoading, RatingClassColor }) {
  const [dataCrytoTab] = useState([
    {
      id: 1,
      title: "Crypto",
    },
    {
      id: 2,
      title: "DeFi",
    },
    {
      id: 3,
      title: "BSC",
    },
    {
      id: 4,
      title: "NFT",
    },
    {
      id: 5,
      title: "Metaverse",
    },
    {
      id: 6,
      title: "Polkadot",
    },
    {
      id: 7,
      title: "Solana",
    },
    {
      id: 8,
      title: "Opensea",
    },
    {
      id: 9,
      title: "Makersplace",
    },
  ]);

  console.log("COTEST", Coins);

  return (
    <section
      className="crypto"
      data-aos="fade-up"
      data-aos-duration="1000"
      style={{ marginTop: "1vh", marginBottom: "15vh" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="crypto__main" style={{ padding: "30px" }}>
              <Tabs style={{ marginTop: "0px" }}>
                {dataCrytoTab.map((data) => {
                  return (
                    <TabPanel key={data.id}>
                      {isLoading ? (
                        <center>
                          <span
                            style={{
                              border: "5px solid #3772ff",
                              borderBottomColor: "transparent",
                            }}
                            class="loader"
                          ></span>
                        </center>
                      ) : (
                        <div
                          className="content-inner"
                          style={{ justifyContent: "space-evenly" }}
                        >
                          {Coins.slice(0, 4).map((data) => {
                            console.log(data);
                            return (
                              <div
                                key={data.id}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "40px",
                                }}
                                className={`crypto-box active`}
                              >
                                <div className="top">
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "5px",
                                      alignItems: "center",
                                    }}
                                    to="#"
                                  >
                                    <img
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "50%",
                                      }}
                                      src={
                                        data.attributes.icon_url?.length > 0
                                          ? data.attributes.icon_url
                                          : process.env.REACT_APP_SERVER_URL +
                                            data.attributes.icon.data.attributes
                                              .url
                                      }
                                      alt="rockie"
                                    />
                                    <span>{data.attributes.name}</span>
                                    <span className="unit">
                                      {data.attributes.ticker}
                                    </span>
                                  </div>
                                </div>
                                {data.attributes.platforms.data.length == 0 ? (
                                  <h6 className="price">
                                    USD{" "}
                                    {new Intl.NumberFormat("en-US", {
                                      currency: "USD",
                                    }).format(
                                      parseFloat(data.attributes.price).toFixed(
                                        2
                                      )
                                    )}
                                  </h6>
                                ) : (
                                  <div style={{ display: "flex", gap: "5px" }}>
                                    {data.attributes.platforms.data.map(
                                      (plat) => {
                                        return (
                                          <div>
                                            <img
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                              src={
                                                process.env
                                                  .REACT_APP_SERVER_URL +
                                                Platforms[plat.attributes.title]
                                              }
                                              alt="rockie"
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                )}

                                {data.attributes.platforms.data.length == 0 ? (
                                  <div
                                    className="bottom"
                                    style={{
                                      gap: "5px",
                                      alignItems: "center",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <p>Trust Rating</p>

                                    <p
                                      className={RatingClassColor(
                                        parseFloat(data.attributes.trust_rating)
                                      )}
                                    >
                                      {data.attributes.trust_rating}
                                    </p>
                                  </div>
                                ) : (
                                  <div
                                    className="bottom"
                                    style={{
                                      gap: "5px",
                                      alignItems: "center",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <p>Earning Rating</p>

                                    <p
                                      className={RatingClassColor(
                                        parseFloat(
                                          data.attributes.earnings_rating
                                        )
                                      )}
                                    >
                                      {data.attributes.earnings_rating}
                                    </p>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </TabPanel>
                  );
                })}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Crypto04;
