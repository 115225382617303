import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import img1 from "../../assets/images/coin/btc.png";
import img2 from "../../assets/images/coin/eth.png";
import img3 from "../../assets/images/coin/tet.png";
import img4 from "../../assets/images/coin/bnb.png";

import "./styles.scss";

import { Link, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import axios from "axios";
import { LoginContext } from "../LoginContext";

Crypto01.propTypes = {};

function Crypto01({}) {
  const [dataCrytoTab] = useState([
    {
      id: 1,
      title: "Crypto",
    },
    {
      id: 2,
      title: "DeFi",
    },
    {
      id: 3,
      title: "BSC",
    },
    {
      id: 4,
      title: "NFT",
    },
    {
      id: 5,
      title: "Metaverse",
    },
    {
      id: 6,
      title: "Polkadot",
    },
    {
      id: 7,
      title: "Solana",
    },
    {
      id: 8,
      title: "Opensea",
    },
    {
      id: 9,
      title: "Makersplace",
    },
  ]);

  const [Tables, setTables] = useState([]);
  const [TablesData, setTablesData] = useState([]);
  const [Columns, setColumns] = useState([]);
  const [Coins, setCoins] = useState([]);
  const [AllCoins, setAllCoins] = useState([]);
  const [TableCoins, setTableCoins] = useState([]);
  const [CurrentTable, setCurrentTable] = useState("");
  const [is_trade, setis_trade] = useState(null);
  const [ColumnsConversions, setColumnsConversions] = useState({});
  const [BlockChains, setBlockChains] = useState({});
  const [Platforms, setPlatforms] = useState({});

  const [Loop, setLoop] = useState();
  const [FetchLoop, setFetchLoop] = useState();
  const [TableSlug, setTableSlug] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [RecentCoins, setRecentCoins] = useState([]);
  const [GainersCoins, setGainersCoins] = useState([]);
  const [SumMarketCap, setSumMarketCap] = useState("");
  const [SumVolume, setSumVolume] = useState("");
  const { theme } = useContext(LoginContext);

  const navigate = useNavigate();

  function sort(arr) {
    return arr.sort(function (a, b) {
      if (a.attributes.h_24 === b.attributes.h_24) return 0;
      return b.attributes.h_24 - a.attributes.h_24;
    });
  }

  useEffect(() => {
    FetchGainersCoins();
    axios(
      process.env.REACT_APP_SERVER_URL + `/api/blockchains?populate=*`
    ).then((res) => {
      if (res.status == 200) {
        if (res.data.data.length > 0) {
          let chains = {};

          res.data.data.forEach((ele) => {
            chains[ele.attributes.title] =
              ele.attributes.icon.data.attributes.url;
          });

          setBlockChains(chains);
        }
      }
    });
    axios(process.env.REACT_APP_SERVER_URL + `/api/platforms?populate=*`).then(
      (res) => {
        if (res.status == 200) {
          if (res.data.data.length > 0) {
            let data = {};

            res.data.data.forEach((ele) => {
              data[ele?.attributes?.title] =
                ele.attributes.icon.data[0].attributes.url;
            });

            setPlatforms(data);
          }
        }
      }
    );

    FetchRecentCoins();
  }, []);

  let myinterval;

  useEffect(() => {
    setLoop();
    // setInterval(() => {
    //   FetchGainersCoins();
    // }, 1000000)
  }, [CurrentTable]);

  const FetchRecentCoins = () => {
    axios(process.env.REACT_APP_PROXY_URL + `/getRecentCoins`).then((res) => {
      setRecentCoins(res.data.data);
    });
  };

  const FetchGainersCoins = () => {
    axios(
      process.env.REACT_APP_SERVER_URL +
        `/api/coins?fields[0]=name&fields[1]=coin_id&fields[2]=market_cap&fields[3]=volume_24&fields[4]=d_7&fields[5]=h_24&fields[6]=name&fields[7]=price&fields[8]=supply&fields[9]=icon_url&filters[coin_id][$ne]=null`
    ).then(async (res) => {
      let temp_coins = res.data.data;

      let coins_comma = [];

      temp_coins.map((ele) => {
        if (ele.attributes.coin_id) {
          coins_comma.push(ele.attributes.coin_id);
        }
      });
      axios(
        process.env.REACT_APP_PROXY_URL + "/api/" + coins_comma.join(",")
      ).then(({ data }) => {
        let coins = [];
        let all_coins = [];

        temp_coins.forEach((ele) => {
          const coin_api = data.data[ele.attributes.coin_id];

          if (ele.attributes["d_7"] == null) {
            ele.attributes["d_7"] =
              coin_api["quote"]["USD"]["percent_change_7d"];
          }
          if (ele.attributes["h_24"] == null) {
            ele.attributes["h_24"] =
              coin_api["quote"]["USD"]["percent_change_24h"];
          }
          if (ele.attributes["market_cap"] == null) {
            ele.attributes["market_cap"] =
              coin_api["quote"]["USD"]["market_cap"];
          }
          if (ele.attributes["name"] == null) {
            ele.attributes["name"] = coin_api["name"];
          }
          if (ele.attributes["price"] == null) {
            ele.attributes["price"] = coin_api["quote"]["USD"]["price"];
          }
          if (ele.attributes["supply"] == null) {
            ele.attributes["supply"] = coin_api["circulating_supply"];
          }
          if (ele.attributes["volume_24"] == null) {
            ele.attributes["volume_24"] =
              coin_api["quote"]["USD"]["volume_24h"];
          }
          if (coin_api["quote"]["USD"]["percent_change_24h"] > 0) {
            coins.push(ele);
          }
          if (coin_api["quote"]["USD"]["market_cap"]) {
            all_coins.push(ele);
          }
        });

        coins.sort(function (a, b) {
          return a.id - b.id;
        });

        let sum_market_cap = 0;
        let sum_volumes = 0;

        all_coins.forEach((ele) => {
          console.log("coins", ele);
          sum_market_cap += parseFloat(ele.attributes.market_cap);
          sum_volumes += parseFloat(ele.attributes.volume_24);
        });

        setSumMarketCap(
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          })
            .format(Math.round(sum_market_cap))
            .toString()
            .split(".")[0]
        );

        setSumVolume(
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          })
            .format(Math.round(sum_volumes))
            .toString()
            .split(".")[0]
        );

        setGainersCoins(sort(coins).slice(0, 4));
      });
    });
  };

  // const HandleTableChange = (table) => {
  //   clearInterval(Loop);
  //   clearInterval(FetchLoop);
  //   setisLoading(true);
  //   setCurrentTable(table);
  //   setCoins([]);
  //   setColumns([]);
  //   FetchCoins(table["value"]);
  //   setFetchLoop(
  //     setInterval(() => {
  //       FetchCoins(table["value"]);
  //     }, 1000000)
  //   );
  // };

  const RatingClassColor = (rating) => {
    if (rating >= 7) {
      return "rating green";
    } else if (rating >= 3) {
      return "rating yellow";
    } else if (rating >= 0) {
      return "rating red";
    }
  };

  return (
    <section
      className="crypto"
      data-aos="fade-up"
      data-aos-duration="1000"
      style={{ marginTop: "20px" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className=""
              style={{
                boxShadow: "unset",
                background: theme == "light" ? "white" : "#141416",
              }}
            >
              <div
                className="stats_crypto"
                style={{ display: "flex", gap: "10px" }}
              >
                <div
                  className={`crypto-box active`}
                  style={{
                    width: "100%",
                    overflow: "auto",
                    background: theme == "light" ? "white" : "#18191d",
                  }}
                >
                  <div
                    style={{ display: "flex", gap: "5px", fontSize: "20px" }}
                  >
                    <div style={{ color: "#3772ff" }}>
                      <i class="fa-solid fa-clock-rotate-left"></i>
                    </div>
                    <div
                      style={{
                        color: theme == "light" ? "black" : "white",
                        fontWeight: "500",
                      }}
                    >
                      Recently Added
                    </div>
                  </div>

                  <table
                    style={{ width: "100%", marginTop: "20px" }}
                    className="table_sp"
                  >
                    <thead>
                      <tr>
                        <th>Token</th>
                        <th style={{ textAlign: "right" }} colSpan={"2"}>
                          Date Added
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {RecentCoins?.map((ele, i) => {
                        let date = new Date(ele.attributes.createdAt);
                        let month = date.toLocaleString("default", {
                          month: "short",
                        });
                        let day = date.getDate();
                        let year = date.getFullYear();

                        let full_date = `${month} ${day}`;
                        return (
                          <tr
                            className="coin_table_tr"
                            onClick={() => {
                              navigate(
                                "/" +
                                  ele.attributes.tables.data[0]["attributes"][
                                    "slug"
                                  ] +
                                  "/" +
                                  ele.attributes.slug
                              );
                            }}
                          >
                            <td
                              style={{
                                color: theme == "light" ? "black" : "white",
                              }}
                            >
                              {i + 1}
                            </td>
                            <td>
                              <div
                                style={{ display: "flex", gap: "5px" }}
                                to="#"
                              >
                                <img
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                  }}
                                  src={
                                    ele.attributes?.icon_url?.length > 0
                                      ? ele.attributes?.icon_url
                                      : process.env.REACT_APP_SERVER_URL +
                                        ele.attributes?.icon?.data?.attributes
                                          ?.url
                                  }
                                  alt="rockie"
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      color:
                                        theme == "light" ? "black" : "white",
                                    }}
                                  >
                                    {ele.attributes.name}
                                  </span>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "gray",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {ele.attributes.ticker}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  textAlign: "right",
                                  color: theme == "light" ? "black" : "white",
                                }}
                              >
                                {full_date}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div
                  className={`crypto-box active`}
                  style={{
                    width: "100%",
                    overflow: "auto",
                    background: theme == "light" ? "white" : "#18191d",
                  }}
                >
                  <div
                    style={{ display: "flex", gap: "5px", fontSize: "20px" }}
                  >
                    <div style={{ color: "#3772ff" }}>
                      <i class="fa-solid fa-chart-line"></i>
                    </div>
                    <div
                      style={{
                        color: theme == "light" ? "black" : "white",
                        fontWeight: "500",
                      }}
                    >
                      Biggest Gainers
                    </div>
                  </div>

                  <table
                    style={{ width: "100%", marginTop: "20px" }}
                    className="table_sp"
                  >
                    <thead>
                      <tr>
                        <th>Token</th>
                        <th style={{ textAlign: "right" }} colSpan={"2"}>
                          24H
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {GainersCoins.map((ele, i) => {
                        let date = new Date(ele.attributes.createdAt);
                        let month = date.toLocaleString("default", {
                          month: "short",
                        });
                        let day = date.getDate();
                        let year = date.getFullYear();

                        let full_date = `${month} ${day}`;
                        return (
                          <tr
                            className="coin_table_tr"
                            onClick={() => {
                              navigate(
                                "/" +
                                  ele.attributes.tables.data[0]["attributes"][
                                    "slug"
                                  ] +
                                  "/" +
                                  ele.attributes.slug
                              );
                            }}
                          >
                            <td
                              style={{
                                color: theme == "light" ? "black" : "white",
                              }}
                            >
                              {i + 1}
                            </td>
                            <td>
                              <div
                                style={{ display: "flex", gap: "5px" }}
                                to="#"
                              >
                                <img
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                  }}
                                  src={
                                    ele.attributes?.icon_url?.length > 0
                                      ? ele.attributes?.icon_url
                                      : process.env.REACT_APP_SERVER_URL +
                                        ele.attributes?.icon?.data?.attributes
                                          ?.url
                                  }
                                  alt="rockie"
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      color:
                                        theme == "light" ? "black" : "white",
                                    }}
                                  >
                                    {ele.attributes.name}
                                  </span>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "gray",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {ele.attributes.ticker}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  textAlign: "right",
                                  color: "rgb(88, 189, 125)",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <i class="fa-solid fa-angle-up"></i>
                                <div>{ele.attributes.h_24.toFixed(2)}%</div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <div
                    className={`crypto-box active`}
                    style={{
                      width: "100%",
                      height: "100%",
                      overflow: "auto",
                      background: theme == "light" ? "white" : "#18191d",
                    }}
                  >
                    <div
                      style={{ display: "flex", gap: "5px", fontSize: "20px" }}
                    >
                      <div style={{ color: "#3772ff" }}>
                        <i class="fa-solid fa-chart-column"></i>
                      </div>
                      <div
                        style={{
                          color: theme == "light" ? "black" : "white",
                          fontWeight: "500",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Gaming Market Cap
                      </div>
                    </div>

                    <br />

                    <h4 style={{ color: "rgb(55, 114, 255)" }}>
                      {SumMarketCap}
                    </h4>
                  </div>
                  <div
                    className={`crypto-box active`}
                    style={{
                      width: "100%",
                      height: "100%",
                      overflow: "auto",
                      background: theme == "light" ? "white" : "#18191d",
                    }}
                  >
                    <div
                      style={{ display: "flex", gap: "5px", fontSize: "20px" }}
                    >
                      <div style={{ color: "#3772ff" }}>
                        <i class="fa-solid fa-hourglass-end"></i>
                      </div>
                      <div
                        style={{
                          color: theme == "light" ? "black" : "white",
                          fontWeight: "500",
                        }}
                      >
                        24h Volume
                      </div>
                    </div>

                    <br />

                    <h4 style={{ color: "rgb(55, 114, 255)" }}>{SumVolume}</h4>
                  </div>
                </div>
              </div>

              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Crypto01;
