import React, { useContext, useEffect, useState } from "react";

import img1 from "../../assets/images/coin/btc-2.png";
import img2 from "../../assets/images/coin/eth-2.png";
import img3 from "../../assets/images/coin/sol.png";
import img4 from "../../assets/images/coin/bnb-2.png";

import chart1 from "../../assets/images/icon/chart-up-2.png";
import chart2 from "../../assets/images/icon/chart-down-2.png";
import axios from "axios";
import { LoginContext } from "../LoginContext";

function Crypto02(props) {
  const { theme } = useContext(LoginContext);
  const [dataCrytoContent] = useState([
    {
      id: 1,
      icon: img1,
      chart: chart1,
      name: "Bitcoin",
      unit: "BTC",
      price: "USD 53,260.20",

      sale: "7.2%",
      class: "success",
    },
    {
      id: 2,
      icon: img2,
      chart: chart2,
      name: "Ethereum",
      unit: "ETH",
      price: "USD 53,260.20",
      sale: "1.8%",
      class: "critical",
    },
    {
      id: 3,
      icon: "https://api.playstoearn.com/uploads/solana_20220613095447_d3095573b6.png",
      chart: chart1,
      name: "Solana",
      unit: "SOL",
      price: "USD 53,260.20",
      sale: "3.54%",
      class: "success",
    },
    {
      id: 4,
      icon: img4,
      chart: chart1,
      name: "BNB",
      unit: "BNB",
      price: "USD 53,260.20",
      sale: "3.24%",
      class: "success",
    },
  ]);

  const [Data, setData] = useState({});

  useEffect(() => {
    axios(process.env.REACT_APP_PROXY_URL + "/api/1,1027,1839,5426").then(
      (res) => {
        let arr = {};

        arr["1"] = {
          price: res.data.data["1"]["quote"]["USD"]["price"],
          h24: res.data.data["1"]["quote"]["USD"]["percent_change_24h"],
        };
        arr["2"] = {
          price: res.data.data["1027"]["quote"]["USD"]["price"],
          h24: res.data.data["1027"]["quote"]["USD"]["percent_change_24h"],
        };
        arr["3"] = {
          price: res.data.data["5426"]["quote"]["USD"]["price"],
          h24: res.data.data["5426"]["quote"]["USD"]["percent_change_24h"],
        };
        arr["4"] = {
          price: res.data.data["1839"]["quote"]["USD"]["price"],
          h24: res.data.data["1839"]["quote"]["USD"]["percent_change_24h"],
        };

        setData(arr);
      }
    );
  }, []);

  return (
    <section
      className="crypto"
      data-aos="fade-up"
      data-aos-duration="1000"
      style={{ marginTop: "0px" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="crypto__main"
              style={{
                boxShadow: "unset",
              }}
            >
              {dataCrytoContent.map((idx) => (
                <div
                  key={idx.id}
                  className="crypto-box"
                  style={{
                    background: theme == "light" ? "#f7f9ff" : "#141414",
                  }}
                >
                  <div className="left">
                    <img
                      style={{ width: "44px" }}
                      src={idx.icon}
                      alt="Rockie"
                    />
                    <h6> {idx.name}</h6>
                    <h6 className="price">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                        .format(parseFloat(Data[idx.id]?.price))
                        .replace("$", " USD ")}
                    </h6>
                  </div>

                  <div
                    className="right"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        width: "fit-content",
                      }}
                      className={
                        parseFloat(Data[idx.id]?.h24) < 0
                          ? `sale critical`
                          : "sale success"
                      }
                    >
                      {parseFloat(Data[idx.id]?.h24) < 0 ? (
                        <i class="fa-solid fa-arrow-down"></i>
                      ) : (
                        <i class="fa-solid fa-arrow-up"></i>
                      )}
                      {parseFloat(Data[idx.id]?.h24).toFixed(2).split(".")[1] ==
                      "00"
                        ? parseFloat(Data[idx.id]?.h24).toFixed(2)
                        : parseFloat(Data[idx.id]?.h24).toFixed(2)}
                      %
                    </p>
                    <p className="unit">{idx.unit}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Crypto02;
